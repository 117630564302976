@import 'variables';
@import 'form';

.agreement-form-title {
  font-size: $font-12;
  margin: 20px 0 10px;
}

.agreement-checkboxs {
  label {
    height: 30px;
    line-height: 30px;
    padding-left: 30px !important;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-weight: bold;

    span {
      font-weight: 800;
    }
  }

  .checkmark {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 20px;
    height: 21px;
    background-color: $white-color;
    border: 1px solid #9a9a99;
    box-sizing: border-box;
    transform: translateY(-50%);

    &.provider {
      border: 1px solid $provider-color;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input[type='checkbox']:checked + .checkmark {
    background-image: url('/images/checkbox-checked.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 13px 14px;
  }

  input[type='checkbox']:checked + .provider {
    background-image: url('/images/provider-checkbox-checked.svg');
  }

  button {
    font-size: $font-14;
  }
}
