@import 'variables';

.search-bar {
  display: flex;
  padding: 0px 24px;

  &.form-group {
    margin-bottom: 0;
  }

  .first-inner {
    .form-control {
      border: 0;
      color: #979797;
    }
  }

  .second-inner {
    flex-grow: 1;
    .form-control {
      height: 40px;
      padding-left: 40px;
      padding-right: 32px;
      background-color: #ffffff;
      border-color: #ffffff;
      position: relative;
      display: flex;
      align-items: center;

      i {
        background-image: url('/images/search-bar-ico.svg');
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        width: 26px;
        height: 26px;
        position: absolute;
        left: 8px;
      }
    }
  }
}

.search-bar-option {
  width: 100%;
  margin-bottom: 8px;

  &.select {
    margin-bottom: 10px;
    box-sizing: border-box;
  }
}

.search-bar-warpper {
  .search-bar-option {
    height: 38px;
  }
}

.search-bar-reset-icon {
  background-image: url('/images/search-bar-reset-icon.svg');
  opacity: 0.2;

  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
}
.search-bar-input {
  height: 100%;
  line-height: 21px;
  font-size: 15px;

  &::placeholder {
    font-size: 15px;
  }
}
.search-bar-input:not(:valid) ~ .search-bar-reset-icon {
  display: none;
}
