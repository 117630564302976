@import 'variables';

.signin-help-tab {
  width: 100%;
  max-width: $max-container-width;
  margin: 0 auto;

  .selected-border {
    border-bottom: 2px solid $brand-color;
    font-weight: 700;
  }
  .unselect-border {
    border-bottom: 0.3px solid $border-color;
  }

  li {
    display: inline-block;
    width: 50%;
    height: 60px;
    line-height: 60px;
    font-size: 1rem;
    text-align: center;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}
