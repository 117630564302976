@import 'variables';
@import 'breakpoint';

.card-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(172.5px, 1fr));
  grid-template-rows: auto;
  column-gap: 15px;
  row-gap: 20px;
  align-items: center;
  justify-items: center;
  margin: 10px auto;
  max-width: 950px;
}
