@import 'variables';

.company-info-form {
  margin-top: 20px;
  padding: 17px 33px 100px 33px;
  line-height: 1.6;

  max-width: 420px;
  margin-left: auto;
  margin-right: auto;

  .company-info-text {
    div,
    span,
    a {
      color: #808080;
      font-size: 12px;
    }

    .company-info-text-title {
      font-weight: 800;
      margin-right: 8px;
    }

    div {
      font-weight: bold;
    }
  }
}

.company-agreement {
  margin-bottom: 47px;

  a {
    width: 25%;
    font-size: 12px;
    font-weight: 800;
    color: black;
    margin-right: 25px;

    &:last-of-type {
      margin-right: 0;
    }

    &.gray-agreement-link {
      font-weight: bold;
      color: #808080;
    }
  }

  a:hover,
  a:active {
    color: black;
  }
}
.company-info-link {
  color: $brand-color;
}

.division-line {
  margin-left: 8px;
  margin-right: 8px;
}
