@import 'variables';

$button-size: 24px;

.picture-view {
  width: 100px;
  height: 100px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border: 1px solid $border-color;
}

.picture-view-btn {
  position: absolute;
  top: 0;
  right: 0;
  text-indent: -1000rem;
  width: $button-size;
  height: $button-size;
  background-image: url('/images/minus-btn.svg');
  background-size: $button-size $button-size;
}
