@import 'variables';

.limit-count {
  padding: 0 16px;
}

.limit-count-view {
  display: flex;
  width: 100%;
  height: 35px;
  margin: 16px auto 10px;
  padding: 0 16px;
  max-width: 327px;
  font-weight: bold;
  // background-color: $from-color;
  // border-radius: 20px;
  color: $black-color;
  line-height: 35px;
  font-size: $font-10;

  &.out {
    color: $gray-300;
  }
}

.limit-count-text {
  &.limit {
    position: relative;
    width: 45%;
    margin-right: auto;
    text-align: left;
    color: $secondary-color;
    font-weight: normal;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 17px;
      background-color: $secondary-color;
      right: 0;
      top: 50%;
      margin-top: -9px;
    }
  }

  &.count {
    width: 55%;
    margin-left: auto;
    font-weight: 600;
    text-align: right;
  }

  .number {
    text-decoration: underline;
  }
}
