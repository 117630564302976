@import 'variables';

.render-option-pricelist-field {
  & > li {
    border-bottom: 0.5px solid;
    border-color: $gray-300;
    //border-top: 0.5px solid #9b9b9b;
    margin-bottom: 8px;
    //padding-top: 15px;
    padding-bottom: 24px;

    &:first-child {
      border-top: none;
    }

    .form-radio {
      background-color: #ffffff;
    }
  }

  .incentive-price-form {
    .rate-group {
      background-color: #f8f8f9;

      .incentive-price-field-set {
        margin: 0;
      }
    }

    .sum-calc-group {
      padding: 24px;
    }

    .incentive-price-field-set {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .form-group {
        width: 50%;
      }
    }
  }

  .rate-group {
    .incentive-struc-form-preview {
      margin-left: auto;
    }
  }

  .incentive-price-field-set-right-button {
    margin-left: auto;
    margin-top: 38px;
    font-weight: 800;
  }
}
