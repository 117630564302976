.bookmark-tab {
  width: 100%;
  border-bottom: 1px solid #ddd;

  ul {
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
  }

  li {
    width: 33.33%;
    text-align: center;
  }

  img {
    width: auto;
    height: 35px;
    // border-radius: 100%;
    display: block;
    margin: 0 auto 5px;
  }

  button {
    width: 100%;
    font-size: 0.8125rem;
    padding: 16px 0;
  }
}

.bookmark-tab-line {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 1px;
    height: 50px;
    background-color: #ddd;
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    right: 0;
  }
}
