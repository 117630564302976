@import 'variables';

.agreement-form-text {
  // position: relative;
  border: 1px solid $border-color;
  height: 180px;
  width: 100%;
  // overflow: auto;
  padding: 10px 0;
  border-radius: 5px;
  height: 400px;
  -webkit-overflow-scrolling: touch !important;
  overflow-y: auto !important;

  div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: auto;
    //overflow-y: scroll;
    // -webkit-overflow-scrolling: touch !important;
    // overflow-y: touch !important;
  }

  iframe {
    width: 100%;
    height: 101%;
  }
}
