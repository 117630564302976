@import 'variables';

.onestore-list-template-wrapper {
  .onestore-list-template-item {
    display: flex;
    align-items: center;
    padding: 12px 0;

    .onestore-list-template-item-profile-image {
      border: 1px solid $from-color;
      border-radius: 50%;
      margin-right: 7px;

      &.provider-connect-store-profile-image {
        border: 0;
      }

      &.is-provider-profile {
        border-color: $provider-color;
      }
    }

    .onestore-list-template-item-image {
      width: 55px;
      height: 55px;
      margin-right: 23px;
      position: relative;

      .onestore-list-template-item-main-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }

      .onestore-list-template-item-sub-image {
        width: 30px;
        height: 30px;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -15px;

        border: 1px solid $from-color;
        border-radius: 50%;

        &.no-image-border {
          border: 0;
        }
      }
    }

    .onestore-list-template-item-name {
      font-weight: 800;
      font-size: 12px;
      color: #000000;
    }

    .onestore-list-template-item-link-icon {
      margin-left: auto;
    }
  }
}
