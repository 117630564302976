@import 'variables';

.onestore-setting-menu-wrapper {
  max-width: 420px;
  margin: 0 auto;
  padding-bottom: 20px;
  border-bottom: 1px solid $border-color;

  ul {
    li {
      padding: 12px 20px 12px 12px;

      .onestore-setting-menu {
        font-weight: bold;
        font-size: 13px;
        display: flex;
        align-items: center;
        color: black;
        width: 100%;
      }

      .onestore-setting-menu-icon {
        margin-right: 12px;
      }

      .onestore-setting-link-icon {
        margin-left: auto;
      }
    }
  }
}
