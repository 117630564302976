.policy-container {
  font-size: 0.875rem;
  line-height: 2rem;

  h2 {
    font-size: 1.125rem;
  }

  h3 {
    font-size: 1rem;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    border-color: grey;
    width: 100%;
  }

  th {
    border-bottom: 0 none;
    font-weight: 600;
    text-align: center;
    background-color: #f7f5f8;
    border: 1px solid #dddfe1;
  }

  td {
    vertical-align: middle;
    padding: 8px 5px;
    border: 1px solid #dddfe1;
    text-align: center;
    line-height: 18px;
  }
}
