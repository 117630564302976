@import 'variables';
@import 'breakpoint';
@import 'form';

$label-width: 100px;

.discover-info-form {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;

  .discover-info-text {
    div,
    span,
    a {
      color: #808080;
      font-size: 12px;
      font-weight: bold;
      margin-right: 8px;
      padding-bottom: 10px;
      line-height: 1.5;
    }

    .discover-info-text-title {
      font-weight: 800;
      margin-right: 8px;
    }
  }
}

.discover-field {
  margin-top: 35px;
  .form-button {
    width: 72px;
  }
}

.discover-field-image {
  display: flex;
  flex-wrap: wrap;
  /* form label 크기가 100px임 */
  width: calc(100% - #{$label-width});

  .picture-input,
  .picture-view {
    width: 90px;
    height: 90px;
    margin-bottom: 5px;

    @include max-width-small-phone {
      width: 85px;
      height: 85px;
    }
  }

  .picture-view {
    display: inline-block;
    margin-right: 5px;
  }
}

.discover-field-image-description {
  width: calc(100% - 100px);
  margin-top: 10px;
  line-height: 1.5;
  font-size: $font-10;
  color: #a6a7aa;
}
