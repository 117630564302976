@import 'variables';

.sns-edit-form {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;

  .sns-login-box {
    height: 70px;

    .sns-login-box-left {
      width: 80%;
      display: flex;
      align-items: center;

      .sns-img {
        width: 38px;
        height: 38px;
      }

      .sns-name {
        font-size: 12px;
        font-weight: 800;
        margin-right: 22px;
      }
    }

    .sns-btn-box {
      button {
        position: relative;
      }

      .toggle-button-box {
        width: 25px;

        label.toggle-button {
          width: initial;
          height: 15px;
          line-height: 15px;
          font-weight: bold;
          font-size: 11px;
          color: #808080;

          .slider {
            width: 25px;
            height: 15px;

            &:before {
              width: 15px;
              height: 15px;
            }
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(13px);
            -ms-transform: translateX(13px);
            transform: translateX(13px);
          }
        }
      }
    }
  }
}
