.mentions {
  /* grid-area: input-control;
  display: block;
  width: 90%; */
}

/* .mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
} */

.mentions--multiLine .mentions__control {
  width: 100%;
  height: 50px;
  background-color: '#fff';
  font-size: 14px;
  font-weight: bold;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 63px;
}
.mentions--multiLine .mentions__input {
  padding: 9px;
  border: 1px solid darkgrey;
  border-radius: 4px;
}

.mentions--multiLine .mentions__input:focus {
  border: 2px solid black;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  background-color: #cee4e5;

  /* position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none; */
}
