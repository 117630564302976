@import 'variables';

.search-list-header {
  &.provider {
    li {
      width: 50%;
    }
  }
}

.search-list-item {
  &.provider {
    .search-list-item-text {
      display: inline-block;
      width: 50%;
    }
  }
}
