@import 'variables';

.gift-confirm-form {
  width: 100%;
  max-width: $max-container-width;
  margin: 0 auto;
  padding: 16px;
  margin-bottom: 50px;
  border-top: 9px solid #ebebeb;

  .form-title {
    margin-top: 26px;
    font-size: 15px;
    font-weight: bold;
  }

  .form-desc {
    color: $from-color;
    font-size: 10px;
  }

  .form-group {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    .form-label {
      display: inline-block;
      width: 80px;
      font-size: 12px;
      color: #a6a6a6;
    }
    strong {
      font-weight: bold;
      font-size: 15px;
    }
  }

  .provider-info {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    margin-top: 16px;

    img {
      width: 100%;
      height: 100%;
      margin-right: 16px;
      max-width: 35px;
      max-height: 35px;
      border-radius: 100%;
    }

    div {
      line-height: 1.3;
      span {
        font-size: 15px;
        margin-right: 8px;
      }
    }

    a {
      margin-left: auto;
      color: $from-color;
    }
  }

  .from-confirm-button {
    width: 90%;
    height: 50px;
  }

  & + .company-info-form {
    display: none;
  }
}

.order-status-item-footer {
  grid-area: footer;

  .label {
    font-size: $font-12;
    font-weight: normal;
    color: #999898;
  }
  .value {
    font-size: $font-12;
    font-weight: 800;
  }
  button {
    width: 40px;
    height: 20px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: fit;
    background-color: none;

    &.closed {
      background-image: url('/images/more-button-2.svg');
    }

    &.open {
      background-image: url('/images/more-button-close-2.svg');
    }
  }
}

.receiver-provider-info-form {
  border-top: 9px solid #ebebeb;
  border-bottom: 9px solid #ebebeb;
  padding: 14px 0;

  .receiver-provider-info-form-desc {
    color: $from-color;
    font-size: 10px;
  }

  .info-form-title {
    margin-top: 26px;
    h3 {
      font-size: 15px;
    }
  }

  .recv-title {
  }

  .delivery-message {
    display: flex;
    margin: 32px 0 24px;
    color: $secondary-color;
    font-size: $font-10;
    line-height: 1.3;

    span {
      display: block;
      width: 11px;
      height: 11px;
      margin: 0px 5px 0 0;
      border-radius: 100%;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
      color: $brand-color;
      line-height: 11px;
      text-align: center;
      font-size: $font-10;
    }
  }
}
