@import 'variables';
@import 'breakpoint';

.slick-arrow {
  text-indent: -1000rem;
  z-index: 1;
  @include max-width-phone {
    visibility: hidden;
  }

  &.slick-prev {
    width: 30px;
    height: 30px;
    left: 13px;
    background-color: rgba(0, 0, 0, 0.4);
    background-position: center center;
    border-radius: 5px;
    background-image: url('/images/banner-left-icon2.svg');
    background-repeat: no-repeat;
  }

  &.slick-next {
    width: 30px;
    height: 30px;
    right: 13px;
    background-color: rgba(0, 0, 0, 0.4);
    background-position: center center;
    border-radius: 5px;
    background-image: url('/images/banner-right-icon2.svg');
    background-repeat: no-repeat;
  }
}

.slick-dots {
  li {
    /* margin: 0px -2px 0px -2px; */
    width: 10px;
    height: 10px;

    button {
      padding: 0;
    }

    button::before {
      color: #ffffff;
      opacity: 0.5;
    }

    .custom-slick-dot {
      width: 10px;
      height: 10px;
      background-color: #ffffff;
      border-color: 1px solid #ffffff;
      border-radius: 50%;
      opacity: 0.5;
    }
  }

  li.slick-active {
    button::before {
      color: #ffffff;
      opacity: 1;
    }

    .custom-slick-dot {
      opacity: 1;
    }
  }
}
