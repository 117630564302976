$profile-image: 45px;
$store-image: 45px;

.notify-list {
  max-width: 500px;
  width: 100%;
}

.notify-item-link {
  color: #000;

  &:last-of-type {
    .notify-item {
      border-bottom: 0;
    }
  }
}

.notify-item {
  border-bottom: 1px solid #ddd;
  padding: 16px 24px 16px 24px;
}

.notify-item-profile {
  width: $profile-image;
  height: $profile-image;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
}

.notify-item-store-image {
  width: $store-image;
  height: $store-image;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.notify-message-form {
  color: #a4a5a8;

  .notify-title {
    color: #000;
    font-weight: bold;
    font-weight: 800;
    margin-bottom: 8px;
    font-size: 13px;
  }

  .notify-message {
    color: #000;
    margin-bottom: 8px;
    line-height: 1.4;

    span {
      word-break: break-word;
      font-size: 13px;
    }
  }

  .notify-date {
    font-size: 10px;
  }
}
