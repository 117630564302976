@import 'form';
@import 'variables';

.member-edit-form {
  @include form;

  .profile-image-edit-btn {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 176px;
    bottom: 17px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.member-edit-optional-form {
  padding-top: 16px;
  border-top: 1px solid $border-color;
}
