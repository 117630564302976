@import 'variables';

.empty-list {
  font-size: $font-20;

  padding-top: 120px;
  padding-bottom: 120px;

  img {
    margin: 0 auto;
  }

  .empty-list-text-box {
    padding-top: 25px;
  }
  .empty-list-contentText {
    font-size: 15px;
  }

  .empty-list-subText {
    margin-top: 10px;
    font-size: 14px;
    color: #bbbbbb;
  }
}

.one-experience-empty-list {
  margin-top: 100px;
}
.one-store-add-button {
  width: 100px;
  height: 100px;
  background-image: url('/images/onestore-add-btn.svg');
  background-size: cover;
  margin: 0 auto;
  display: block;
}
.one-store-empty-text {
  line-height: 20px;
}
