@import 'variables';

.provider-sales-list {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 8px 16px;
  align-items: center;
  max-width: $max-container-width;
  border-bottom: 1px solid $border-color;
}
