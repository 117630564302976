@import 'variables';

.discover-modal-2 {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  .modal-content {
    .modal-close-button {
      visibility: hidden;
    }

    .modal-header-text {
      img {
        width: 75px;
        height: 75px;
        border-radius: 4px;
      }
    }

    .modal-body {
      overflow-y: initial;
    }

    .modal-body-text {
      margin-bottom: 40px;

      span {
        color: $from-color;
        font-weight: 800;
        font-size: 19px;
        line-height: 1.4;
      }
    }

    .modal-body-text2 {
      margin-bottom: 40px;

      span {
        color: black;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.9;
      }
    }
  }

  .modal-center-top-icon {
    position: absolute;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);

    width: 77px;
  }

  .decorate-img-wrapper {
    width: 75px;
    margin: 0 auto;

    .decorate-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 0;
      padding-top: 100%;
      width: 100%;
    }
  }

  .center-circle-image {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    border: 3px solid $from-color;
    background-position: center;
    background-size: cover;
  }

  .col-button-group {
    .button {
      width: 100%;
      font-size: 15px;
      font-weight: bold;
      height: 40px;

      &.btn-close {
        color: $from-color;
        background-color: white;
        border: 1px solid $button-border-color;
      }
    }
  }
}
