@import 'variables';

.gift-message-background {
  max-width: $max-container-width;
  margin: 0 auto;
  padding: 51px 40px 67px 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.gift-message-story {
  position: relative;
  background-color: #ffffff;
  opacity: 0.9;
  border-radius: 10px;
}

.in-form {
  padding: 20px 20px 60px 20px;

  .circle-profile-2 {
    position: relative;
    width: 95px;
    border-radius: 50%;
    margin: 0 auto;

    .sender-img {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      height: 0;
      padding-top: 100%;
      width: 100%;
    }
    .store-list-item-text {
      line-height: 93px;
      background-color: $gray-200;
      border: 1px solid $border-color;
      border-radius: 50%;
      text-align: center;

      .store-list-item-text-name {
        width: 95px;
        color: $gray-800;
        font-size: 0.8125rem;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
        text-overflow: ellipsis;
      }
    }
    .circle-profile-small-icon {
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);

      width: 69px;
      height: 69px;

      .icon {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  .decorate-img-wrapper {
    width: 75px;
    margin: 0 auto;

    .decorate-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 0;
      padding-top: 100%;
      width: 100%;
    }
  }

  .gift-title-msg-2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    text-align: center;
    margin-top: 22px;
    margin-bottom: 33px;
    color: $from-color;

    span {
      font-size: inherit;
      font-weight: 800;
    }
  }

  .gift-message-product-name {
    font-weight: bold;
    font-size: 14px;
    position: relative;
    line-height: 1.3;
    color: black;
    text-align: center;
    margin-bottom: 31px;
  }
}

.circle-profile {
  position: absolute;
  width: 42px; /* 46px; */
  height: 46px;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  /*
  background-color: #ffffff;
  */
  border-radius: 50%;
  margin: 0 auto;
  .sender-img {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    /*
    border-radius: 50%;
    */
    height: 100%;
    width: 100%;
  }
  .store-list-item-text {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

.gift-title {
  position: relative;
  .gift-icon {
    width: 33px;
    height: 33px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .gift-icon-img {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }
  .gift-title-msg {
    font-size: 14px;
    font-weight: bold;
    color: $black-color;
    padding: 10px 0px;
  }
}

.gift-link {
  margin-top: 8px;
  margin-bottom: 10px;

  .gift-link-img {
    border-top: 0.5px solid #c3c3c3;
    border-left: 0.5px solid #c3c3c3;
    border-right: 0.5px solid #c3c3c3;
    border-radius: 8px 8px 0 0;
    text-align: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 183px;
      object-fit: cover;
      // object-position: top;
      vertical-align: middle;
      border-radius: 8px 8px 0 0;
    }
  }

  .gift-link-msg {
    border-left: 0.5px solid #c3c3c3;
    border-right: 0.5px solid #c3c3c3;
    border-bottom: 0.5px solid #c3c3c3;
    border-radius: 0 0 8px 8px;
    background-color: #f4f4f4;

    div {
      color: #212529;
      padding-left: 10px;
      padding-right: 10px;
    }

    .gift-product {
      font-size: 15px;
      padding-top: 10px;
    }

    .gift-option {
      padding-top: 5px;
    }

    .detail-button {
      color: #9b9b9b;
      padding-top: 5px;
      padding-bottom: 10px;
      font-size: 13px;
    }
  }
}

.gift-message-box {
  .gift-message {
    color: $black-color;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.6;

    button {
      font-size: 14px;
      font-weight: normal;
      color: $from-color;
    }
  }
}

.received-gift-form-button {
  width: 100%;
  padding-top: 22px;
  padding-bottom: 22px;
  font-weight: 800;
  font-size: 18px;
  border-radius: 0;
}

.gift-message-top-box {
  max-width: $max-container-width;
  margin: 0 auto;
  background-color: #eeeeee;

  .gift-message-top-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    .circle-profile-2 {
      position: relative;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      .sender-img {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }
      .store-list-item-text {
      }
      .circle-profile-small-icon {
        position: absolute;
        bottom: 0;
        right: -11px;
      }
    }
  }
}
.gift-message-product-wrapper {
  margin-bottom: 30px;
  .gift-message-product-image-wrapper {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 10px;

    .gift-message-product-image {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 10px;
      height: 100%;
      width: 100%;
    }
  }

  .gift-message-product-info {
    text-align: center;

    .gift-message-product-name {
      font-weight: 800;
      font-size: 14px;
      padding: 5px 0;
      position: relative;
      line-height: 1.3;
      color: black;

      &:after {
        margin-left: 3px;
        position: absolute;
        bottom: 4px;
        display: inline-block;
        background-image: url('/images/search-icon-small.svg');
        content: '';
        width: 20px;
        height: 20px;
      }
    }

    .gift-message-product-opt {
      font-weight: bold;
      font-size: 12px;
      padding: 6px 0;

      color: black;
    }
  }
}
