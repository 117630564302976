@import 'variables';

.service-contact-form {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  padding: 14px;

  .service-contact-box {
    line-height: 2;
    margin-bottom: 65px;

    .service-contact-big-text {
      font-size: 18px;
      font-weight: 800;
      margin-bottom: 5px;
      margin-top: 10px;
    }

    .service-contact-regular-text {
      font-size: 12.5px;
      line-height: 1.7;
    }
  }

  .service-kakao-box {
    border-bottom: 1px solid $border-color;
    padding-bottom: 8px;

    .service-kakao-big-text {
      font-size: 15px;
      font-weight: 800;
      margin-bottom: 20px;
    }

    .service-kakao-regular-text {
      font-size: 13px;
      font-weight: bold;
      line-height: 1.7;
    }

    .service-kakao-light-text {
      font-size: 10px;
      font-weight: bold;
      color: #939292;
    }

    button {
      //  background-color: #fef01b;
      //  font-size: 16px;
      //  font-weight: 800;
      //  color: #3a1e1e;
      vertical-align: middle;
      margin-top: 10px;
      margin-bottom: 22px;
      padding-top: 3px;
      padding-bottom: 1px;
      padding-left: 2px;
      padding-right: 2px;

      img {
        //  vertical-align: middle;
        //  margin-right: 8px;
      }
    }
  }

  .service-contact-list-box {
    margin-top: 45px;
    display: flex;
    border-bottom: 1px solid $border-color;
    padding-bottom: 26px;

    .service-contact-list-item {
      position: relative;
      width: 50%;
      color: black;

      .service-contact-list-big-text {
        font-size: 15px;
        font-weight: 800;
        margin-bottom: 24px;
      }

      .service-contact-list-link-text {
        display: inline-block;
        color: $from-color;
        font-size: 15px;
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 24px;
      }

      .service-contact-list-small-text {
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  .service-time-box {
    margin-top: 45px;

    .service-time-big-text {
      font-size: 15px;
      font-weight: 800;
      margin-bottom: 20px;
    }

    .service-time-regular-text {
      font-size: 13px;
      font-weight: bold;
      line-height: 2;
    }
  }
}

.service-contact-form + .company-info-form {
  display: none;
}
