@import 'variables';

.incentive-struct-label {
  display: inline-block;
  width: 100%;
  font-size: 15px;
}

.default-rate-form {
  padding: 5px;

  li {
    display: flex;
    margin-bottom: 15px;
    align-items: center;

    &.text {
      margin: 16px 0 24px;
    }
  }
}

.incentive-struc-form-label {
  display: inline-block;
  width: 110px;
  font-size: 0.8125rem;
  font-weight: bold;
}

.form-incentive-input-width {
  width: calc(100% - 110px);
}
