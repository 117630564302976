@import 'variables';
@import 'form';

.signup {
  @include form;
}

.signup-button {
  width: 100%;
  height: 50px;
  margin: 16px 0;
  border: 0;
  font-size: $font-18;

  &.kakao {
    background-color: $warning-color;
    color: #392020;
  }

  &:disabled {
    background-color: $gray-200;
    color: $secondary-color;
  }
}

.form-picture-label {
  line-height: 100px;
}

.signup-optional-form {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.signup-agreement-checkboxs {
  @include form-checkbox-radio;
}

.signup-error-modal {
  line-height: 1.5;
}

// 공급자 회원 가입
.provider-signup-equal-address {
  margin-left: 100px;
}

.hidden {
  display: none;
}

.signup-warning-field {
  position: relative;

  .warning-mark {
    position: absolute;
    top: 0px;
    left: 98px;
    color: $brand-color;
  }

  .provider-id-warning {
    font-size: 12px;
    margin-left: 113px;
    margin-bottom: 10px;
    color: #a6a7aa;

    span {
      color: $brand-color;
      font-size: 12px;
    }
  }
}
