@import 'variables';
@import 'form';

.buyerinfo-save {
  padding-left: 100px;
  margin-bottom: 0.5rem;
}

.buyerinfo-save-checkboxs {
  label {
    line-height: 1.2;
    padding-left: 26px !important;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .checkmark {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 20px;
    height: 21px;
    background-color: $white-color;
    box-sizing: border-box;
    transform: translateY(-50%);
    background-image: url('/images/checkbox-unchecked-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input[type='checkbox']:checked + .checkmark {
    background-image: url('/images/checkbox-checked-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  button {
    font-size: $font-14;
  }
}
