@import 'variables';

.tutorial-form {
  color: $secondary-color;
  .tutorial-head-text {
    font-size: 19px;
  }
}

.tutorial-mail {
  padding-bottom: 16px;
  border-bottom: 1px solid $border-color;
  img {
    width: 50px;
    height: auto;
  }
}

.tutorial-slide {
  text-align: center;
  overflow: hidden;

  .slick-dots {
    bottom: 16px;
  }
}

.tutorial-slide-item {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(
    1627 / 751 * 100%
  ); /* calc (이미지 높이 ÷ 이미지 가로 × 100 %) */

  background-position: center center;
  background-size: cover;
  margin: 0 auto;
}

.slide-index {
  color: #ffffff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.tutorial-index {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 48px;
}
