@import 'breakpoint';
@import 'variables';

.giftbox-profile-left {
  position: relative;
  width: 55px;
  min-width: 55px;
  height: 55px;
  line-height: 55px;
  margin-right: 5px;
  margin-left: 5px;

  .centered-text {
    position: absolute;
    width: auto;
    height: auto;
    min-width: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: #000000;
  }
}

.gift-box-cancel-gift {
  opacity: 0.3;
}

.giftbox-product-image {
  max-width: 50px;
  max-height: 50px;
  width: auto;
  height: auto;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.grid-vertical-center {
  vertical-align: middle;
  div {
    align-items: center;
  }
}

.giftbox-gift-from {
  position: relative;
  .gift-name {
    text-decoration: underline;
  }
  .not-assign-name {
    color: $text-color;
    cursor: auto;
  }
}
.balnce-save-money-list-item {
  .gift-receive-btn {
    padding: 0.375rem 0.515rem;
    @include max-width-small-phone {
      padding: 0.375rem 0.165rem;
    }

    min-width: 66px;
    max-width: 77px;
    width: 100%;
  }
}

.ReactVirtualized__Table__headerRow {
  border-top: 1px solid #ddd;
}
