@import 'variables';
/*공급자 상품관리 옵션이미지*/
$input-size: 100px;

.picture-input {
  position: relative;
  width: $input-size;
  height: $input-size;

  input {
    display: none;
  }

  .async-validating {
    margin: -10px;
    right: 50%;
    top: 50%;
  }
}

.picture-input-cover {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.picture-input-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  background-origin: border-box;
  cursor: pointer;
}

.picture-input-edit-btn {
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: -8px;
  right: -8px;
}

.picture-input-modal {
  line-height: 1.5;
}
