@import 'variables';

.provider-service-info-wrapper {
  max-width: 420px;
  margin: 0 auto;

  .provider-service-contact-info {
    padding: 0px 16px 16px 16px;
    display: flex;

    .provider-service-contact {
      div {
        font-weight: 800;
        font-size: 15px;
      }

      a {
        display: block;
        color: $provider-color;
        margin-top: 0px;
        margin-bottom: 20px;
        text-decoration: underline;
        font-weight: bold;
      }
    }

    /*
    .provider-service-contact-tel {
      width: 50%;

      div {
        font-weight: 800;
        font-size: 15px;
      }

      a {
        display: block;
        color: $provider-color;
        margin-top: 20px;
        text-decoration: underline;
      }
    }

    .provider-service-contact-email {
      width: 50%;

      div {
        font-weight: 800;
        font-size: 15px;
      }

      a {
        display: block;
        color: $provider-color;
        margin-top: 20px;
        text-decoration: underline;
      }
    }
    */
  }

  .provider-service-provider-info {
    padding: 20px 20px 60px 20px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    .provider-service-provider-name {
      font-weight: 800;
      font-size: 15px;
    }

    .provider-info-help {
      li {
        display: flex;
        margin-top: 20px;
        line-height: 1.3;

        .title {
          flex-basis: 77px;
          font-size: 12px;
          color: #a1a0a0;
        }

        .sub {
          flex-basis: calc(100% - 77px);
          font-weight: bold;
        }
      }
    }
  }
}
