@import 'variables';

.profile-picture {
  position: relative;
  width: 47px;
  height: 47px;
  margin: 0 auto;
}

.profile-picture-crown {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 23px;

  &.profile-provider-crown {
    width: 28px;
    height: 30px;
    top: -27px;
  }

  &.profile-onestore-crown {
    width: 33px;
  }
}

.profile-picture-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 100%;
  width: 100%;

  .discoverer-recommend-box & {
    // border: 1px solid $from-color;
  }

  .profile-provider-image & {
    // border: 1px solid $provider-color;
  }
}
