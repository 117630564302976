$provider-delivery-font-size: 11px;

.provider-order-label {
}
.provider-order-num {
  border-right: 1px solid #ddd;
  width: 87px;
  height: 81px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 15px;
  padding-right: 1rem;
  div {
    font-size: $provider-delivery-font-size;
    color: #2f2a2b;
  }

  span {
    width: 100%;
    word-wrap: break-word;
    font-size: $provider-delivery-font-size;
    color: #838383;
  }
}
.provider-delivery {
  width: 55%;
  color: #838383;
  line-height: 18.5px;

  div {
    font-size: $provider-delivery-font-size;
  }

  span {
    font-size: $provider-delivery-font-size;
    color: #2f2a2b;
    font-weight: 700;
  }
}
.provider-delivery-form {
  width: 90px;
  text-align: center;
  padding: 3px;

  button {
    width: 80px;
    border-radius: 25px;
    padding: 3px;
    margin-bottom: 5px;
    font-size: 14px;
  }

  span {
    display: block;
    color: #a4a5a8;
    font-size: 11px;
    word-break: break-all;
  }

  .provider-delivery-dl-number {
    font-size: 13px;
  }
  .provider-delivery-dl-name {
    font-size: 15px;
  }
}
