@import 'variables';

/* The switch - the box around the slider */
.toggle-button-box {
  .async-validating {
    top: 16px;
    left: -20px;
  }
}
.toggle-button {
  position: relative;
  display: inline-block;
  width: 39px;
  height: 22px;
  border-radius: 11.5px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border: solid 1px #d9d9d9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: -3px;
  bottom: -1px;
  background-color: #fbfbfb;
  border: solid 1px #d9d9d9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $brand-color;
  border-color: $brand-color;

  &.primary {
    background-color: $brand-color;
  }

  &.primary,
  &.primary:before {
    border-color: $brand-color;
  }

  &.from {
    background-color: $from-color;
  }

  &.from,
  &.from:before {
    border-color: $from-color;
  }

  &.provider {
    background-color: $provider-color;
  }

  &.provider,
  &.provider:before {
    border-color: $provider-color;
  }
}

/* 일단 필요없을거 같애서 주석처리 해놓음
input:focus + .slider {
  box-shadow: 0 0 1px $brand-color;
}
*/

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  border: solid 1px;

  &.primary {
    border-color: $brand-color;
  }

  &.from {
    border-color: $from-color;
  }

  &.provider {
    border-color: $provider-color;
  }
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
