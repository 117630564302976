@import 'variables';

.share-modal {
  .headerImage {
    width: 50%;
  }

  .share-modal-button-wrapper {
    margin: 28px 0;
    display: flex;
    flex-direction: column;
    font-weight: bold;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 17px;
      position: relative;

      .share-button-img {
        width: 38px;
        height: 38px;
        margin-left: 17px;
        background-position: center;
        background-size: cover;
        position: relative;

        .success-copy-url-image {
          width: 52px;
          height: 52px;
          top: -7px;
          left: -7px;
          position: relative;
          // opacity: 70%;
          // animation-name: copy-url-moving;
          // animation-duration: 1.5s;
          // animation-play-state: running;
          // animation-fill-mode: forwards;
        }

        /* @keyframes copy-url-moving {
          0% {
            opacity: 0%;
          }
          100% {
            opacity: 80%;
          }
        } */
      }
    }
  }
}

.share-url {
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  background-color: #f2f2f2;
  margin-bottom: 24px;
}

.share-url-input {
  width: 100%;
  padding: 0 45px 0 7px;
}

.share-url-button {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
}

.share-modal-support-wrapper {
  margin-top: 20px;
  font-size: 17.5px;
  font-weight: 800;
  text-align: center;
  border-color: $black-color;
  color: $black-color;

  &.btn {
  }

  &.btn:not(:hover) {
  }

  &.btn-outline-primary {
  }

  .share-modal-support-image-icon {
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 40px;
    height: 25px;
    margin-right: 4px;
    vertical-align: middle;
  }
}
