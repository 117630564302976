@import 'variables';

.provider-onestore {
  height: 65px;
  .to-item-link {
    width: 80%;
  }
}
.provider-onestore-profile {
  width: 50px;
  height: 50px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  float: left;
}

.provider-onestore-name-number {
  margin-top: 11px;

  div {
    font-size: 12px;
  }

  .provider-onestore-name {
    margin-top: 2px;
    font-size: 15px;
  }
}

.provider-bookmark-div {
  position: relative;
  .bookmark {
    position: static;
    width: 21.6px;
  }
}

.follow-list-wrapper {
  max-width: 420px;
  margin: 0 auto;
  padding: 0 16px;

  .follow-list-label {
    font-weight: 800;
    font-size: 18px;
    padding: 30px 0;
  }

  .follow-list-item {
    display: flex;
    align-items: center;
    padding: 12px 0;

    .follow-list-item-profile-image {
      border: 1px solid $from-color;
      border-radius: 50%;
      margin-right: 7px;

      &.provider-connect-store-profile-image {
        border: 0;
      }

      &.is-provider-profile {
        border-color: $provider-color;
      }
    }

    .follow-list-item-name {
      font-weight: 800;
      font-size: 12px;
      color: #000000;
    }

    .follow-list-item-link-icon {
      margin-left: auto;
    }
  }
}
