@import 'variables';

.adult-mdoal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.adult-modal-image {
  width: 70px;
  height: 70px;
  margin-right: 16px;
}

.adult-modal-text {
  color: $text-color;
  line-height: 1.5;
  text-align: left;
}

.adult-modal-button {
  width: 100%;
}
