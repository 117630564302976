@import 'variables';

.logo-modal-content {
  line-height: 1.3;

  span {
    font-size: $font-14;
    color: #74c358;
  }
}
