@import 'variables';

.provider-delivery-dl-number {
  line-height: 1.3;
}

.input-modal-text {
  font-size: 18px;
}

.text-invoice {
  margin-top: 16px;
  border: 1px solid $border-color;
  border-radius: 5px;
  line-height: 1.3;
}

.delivery-num-input-modal {
  .form-select {
    height: 42px;
    margin-top: 40px;
    border-radius: 2.5px;
    font-size: 11.6px;
  }

  .text-invoice {
    border-color: $button-border-color;
    border-radius: 2.5px;
    font-size: 11.6px;
  }
}
