@import 'breakpoint';
@import 'form';

.container {
  .discover-all-container {
    max-width: 468px;
    margin: 0 auto;
  }
}

.discover-request-header {
  height: 190px;
  margin: 25px;
  display: flex;
  border-bottom: 1px solid #efefef;
}

.discover-profile {
  width: 150px;
  height: 100%;
  display: inline-block;
}

.discover-status {
  width: 80.1px;
  height: 40.4px;
  line-height: 40.4px;
  text-align: center;
  margin: auto;
  color: #ffffff;
  border-radius: 5px;
  font-size: 0.875rem;
}

.discover-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}

.discover-profile-image {
  max-width: 100%;
  max-height: 170px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.discover-profile-image-form {
  position: relative;
  width: 180px;
  height: 100%;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;

  @include only-small-phone {
    width: 150px;
  }
}

.cancel-date {
  margin-top: 30px;
  text-align: center;
}

.request-date {
  margin-top: 30px;
  text-align: center;
}

.date {
  font-size: 11px;
}

.status-reason {
  color: #a4a5a8;
}

div.form-readonly {
  background-color: #f0f0f0;
}

.image-status {
  background-size: cover;
}
.status-area {
  width: 14px;
  height: 150px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 14px 100px;
  float: left;
}

.status-date {
  margin-left: 5px;
  width: 100px;
  height: 150px;
  display: inline-block;
}

.discover-status-history {
  width: 80.1px;
  height: 40.4px;
  line-height: 40.4px;
  text-align: center;
  margin: auto;
  color: #ffffff;
  border-radius: 5px;
  font-size: 0.875rem;
  margin-left: 0px;
  margin-bottom: 15px;
}

.WD-33 {
  height: 33%;
}
.status-text {
  font-size: 12px;
  margin-bottom: 6px;
}
.little-date {
  font-size: 11px;
}

.excavation-form-buttons {
  .button {
    width: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 3px;
  }
}
