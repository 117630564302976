@import 'variables';

.search-list-header {
  padding: 10px 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  li {
    display: inline-block;
    width: 33.33%;
    text-align: center;
    font-weight: 600;
  }
}

.search-list-item {
  padding: 10px 0;
  border-bottom: 1px solid $border-color;

  &:last-of-type {
    border-bottom: 0;
  }

  button {
    width: 100%;
    height: 100%;
  }
}

.search-list-item-text {
  display: inline-block;
  width: 33.33%;
  color: #656565;
  text-align: center;
  line-height: 1.3;
  word-break: break-all;
  vertical-align: middle;
}

.search-list-item-status {
  display: inline-block;
  width: 65px;
  height: 25px;
  margin: 0 auto;
  line-height: 25px;
  color: $white-color;
  border-radius: 5px;

  &.wait,
  &.audit {
    background-color: $warning-color;
    color: $secondary-color;
  }

  &.success {
    background-color: $brand-color;
  }

  &.fail {
    background-color: $danger-color;
  }
}

.search-list-text {
  padding: 60px 0;
  font-size: 15px;
  color: #2d2a2a;
  text-align: center;
}

.search-list-new {
  padding: 16px 0 0;
  border-top: 1px solid $border-color;
  text-align: center;

  button {
    margin-top: 16px;
  }
}

.modal-new {
  height: 50px;
  font-size: 18px;
  font-weight: bold;
}
