@import 'variables';
$store-image: 65px;
$profile-image: 35px;
$search-button-width: 50px;

.to-list {
  max-width: 500px;
  width: 100%;
}

.to-list-search {
  position: relative;
  border-bottom: 1px solid #ddd;
}

.to-list-search-input {
  height: 40px;
  border-radius: 100px !important;
  border: 1px solid $brand-color;
  width: 100%;
  padding-right: $search-button-width;
}

.to-list-search-button {
  position: absolute;
  right: 1rem;
  width: $search-button-width;
  height: 30px;
  top: 50%;
  margin-top: -23px;
  color: $brand-color;
}

.to-item-link {
  color: #000;

  &:last-of-type {
    .to-item {
      border-bottom: 0;
    }
  }
}

.to-item {
  border-bottom: 1px solid #ddd;
}

.to-item-store-image {
  width: $store-image;
  height: $store-image;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.to-item-profile {
  width: $profile-image;
  height: $profile-image;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
}

.to-item-bookmark {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: $brand-color;
  font-size: 1.25rem;
}

.to-item-storeNumber {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: $brand-color;
  color: $white-color;
  border-radius: 5px;
  font-size: 0.875rem;
}
.have-not-list {
  margin: auto;
  text-align: center;
  padding: 35px;
  font-size: 14px;
}
