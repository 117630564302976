@import 'variables';

$provider-image-width: 40px;
$provider-image-height: 54px;

.product-detail,
.product-tabs {
  position: relative;
  text-align: center;
  max-width: 420px;
  margin: 0 auto;
  // padding-top: 115px;

  &.oneproduct-product-detail {
    border-top: 1px solid $border-color;
    padding-top: 16px;
  }

  &.product-list {
    text-align: left;
    padding-top: 0;
  }

  .onestore-profile {
    // 공급자 이미지 있을 때
    width: $provider-image-width;
    height: $provider-image-height;
    margin: 25px auto 5px;

    .onestore-profile-image {
      width: $provider-image-width;
      height: $provider-image-height;
      -webkit-mask-size: $provider-image-width $provider-image-height;
      mask-size: $provider-image-width $provider-image-height;
    }

    .onestore-profile-flag {
      width: 20px;
      height: 25px;
      top: -19px;
      left: 18px;
    }

    // 공급자 이미지 없을 때
    &.onestore-default-profile {
      width: $provider-image-width;
      height: 73px;
      margin-top: 6px;

      .onestore-default-profile-image {
        width: 100%;
        height: 100%;
      }
    }
  }

  .onestore-default-profile-text {
    padding-top: 35px;

    .provider {
      font-size: $font-12;
      height: 2rem;
      line-height: 1rem;
    }
  }

  .product-detail-image-wrapper {
    position: relative;

    .product-detail-image-2 {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;
      background-position: center center;
      background-size: cover;
      margin: 0 auto;
    }
  }

  .html-product-info,
  .product-detail-options,
  .product-detail-info {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.product-detail-store,
.product-detail-product {
  font-weight: 300;
}

.product-detail-store {
  font-size: 1.25rem;
}

.product-detail-store-owner {
  font-size: 1.5rem;
}

.product-detail-title {
  * {
    font-size: $font-20;
  }
}

.product-detail-title-product {
  max-width: 420px;
  margin: 0 auto;
  font-size: $font-16;
  line-height: 1.3;
  padding: 15px 20px;
  color: $from-color;
  font-weight: bold;
  word-break: keep-all;
  min-height: 50px;

  &.oneproduct-title-header-tab {
    width: 100%;
    margin: 0 auto;
    max-width: $max-container-width;
    position: fixed;
    top: $header-height;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    /* height: 50px; */
    z-index: 10;
    background-color: white;
    transition: top 0.2s ease-in-out;

    text-align: center;

    & + div {
      padding-top: 50px;
    }
  }
}

/* 상품 이미지, 공급자 이미지 */
.product-detail-image-product {
  flex-basis: 150px;

  .product-picture {
    width: 150px;
    height: 150px;
    margin: 0 auto;

    img {
      border-radius: 8px;
    }
  }
}

/* 공급자 */
.product-detail-image-provider {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);

  .onestore-profile-cover {
    height: 55px;
  }

  .product-detail-provider-link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 36px;
    color: white;
    font-size: $font-12;
    font-weight: bold;
    line-height: 1.2;
    width: 120px;
    margin-left: 9px;
    text-align: center;
    padding: 4px 9px;
    border: 1px solid #000000;
    border-radius: 3px;
    background-color: #000000;
    opacity: 0.8;

    &:after {
      border-top: 0px solid transparent;
      border-left: 8px solid transparent;
      border-right: 0px solid transparent;
      border-bottom: 5px solid #000000;
      content: '';
      position: absolute;
      top: 14px;
      left: -7px;
    }
  }

  .dynamic-profile-picture-image {
    border: 1px solid white;
  }
}

/* 상품 상세 정보 */

$button-width: 90px;
$text-height: 20px;
$padding: 3px;

.product-text,
.product-detail-price {
  display: flex;
  flex-wrap: nowrap;
}

.product-text {
  padding: 5px 0;
  border-bottom: 1px solid $border-color;
  color: #2d2a2a;

  &:last-of-type {
    border-bottom: 0;
  }
}

.product-text-label {
  margin-top: 2px;
  flex-basis: 100px;
  color: $secondary-color;
  text-align: left;
  font-size: $font-14;
}

.product-text-children {
  position: relative;
  flex-basis: 100%;
  text-align: left;
  line-height: 1.3;
  font-size: $font-14;
  word-break: break-all;
}

/* 가격 상세 정보 */
.product-detail-price {
  margin-bottom: 15px;
  align-items: center;

  &.payment-amount {
    margin-bottom: 8px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.product-detail-price-children {
  margin-left: auto;
  text-align: right;

  &.default {
    display: flex;
    width: calc(100% - 75px);

    .default-option-label {
      margin-left: auto;
      text-align: left;
      margin-right: 10px;
    }
  }

  .cancel {
    font-weight: 800;
    word-break: keep-all;
    color: #9c9b9b;
  }

  .payment-amount & {
    font-size: 18px;
    font-weight: 800;
  }

  .save-money-image & {
    font-size: 12px;
    color: $from-color;
  }
}

.product-detail-price-label {
  color: #9c9b9b;

  .payment-amount & {
    color: black;
  }

  .save-money-image & {
    color: $from-color;
    font-size: 12px;
  }
}

/* 소개 */
.product-text-introduce {
  line-height: 1.5;
  color: $black-color;
  font-weight: normal;
  font-size: 14px;
}

.product-redirect-btn {
  position: absolute;
  padding: 0;
  width: 90px;
  height: 25px;
  line-height: 25px;
  top: 50%;
  right: 0;
  font-size: 0.75rem;
  transform: translateY(-50%);
  border: 0;
}

.product-detail-togggle-button {
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: $font-14;
  background-image: url('/images/product-detail-toggle-btn.svg');
  background-position: center right;
  background-repeat: no-repeat;

  &.active {
    background-image: url('/images/product-detail-toggle-btn-close.svg');
  }
}

.product-detail-info {
  padding: 0 13px;
  border: 1px solid $secondary-color;
  border-radius: 5px;
}

.product-anchor-tabs {
  position: fixed;
  // position: sticky;
  display: block;
  z-index: 4;
  // 헤더 z-index: 5;

  // //
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  margin-left: auto;
  margin-right: auto;
  // position: sticky, fixed 모두 센터로 잡아주는 css;

  top: $header-height;
  background-color: rgba(255, 255, 255, 0.9);
  transition: top 0.2s ease-in-out;

  .product-tab-item {
    max-width: 420px;
    height: 39px;
    margin: 0 auto;
    padding: 0px 16px 6px 16px;

    .tab-count {
      font-size: 13px;
      color: #868686;
    }
  }
}

.html-product-info {
  line-height: 1.6;
  text-align: initial;

  &.one-product-html-info {
    max-width: 420px;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 0 16px;
  }

  img {
    // display: block;
    // margin: 0 auto;
    max-width: 100%;
  }

  span.fr-emoticon.fr-emoticon-img {
    background-repeat: no-repeat !important;
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -0.1em 0.1em 0.1em;
    line-height: 1;
    vertical-align: middle;
  }

  span.fr-emoticon {
    font-weight: 400;
    font-family: Apple Color Emoji, Segoe UI Emoji, NotoColorEmoji,
      Segoe UI Symbol, Android Emoji, EmojiSymbols;
    display: inline;
    line-height: 0;
  }

  .fr-img-caption .fr-img-wrap > span {
    margin: auto;
    display: block;
    padding: 5px 5px 10px;
    font-size: 14px;
    font-weight: bold;
    font-weight: initial;
    box-sizing: border-box;
    -webkit-opacity: 0.9;
    -moz-opacity: 0.9;
    opacity: 0.9;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    width: 100%;
    text-align: center;
  }

  .fr-video {
    text-align: center;
    position: relative;

    & > * {
      box-sizing: content-box;
      max-width: 100%;
      border: none;
    }

    .fr-dvb {
      display: block;
      clear: both;
    }
  }
  strong,
  u,
  span,
  s {
    font-size: 100%;
  }

  strong {
    font-weight: 800;
  }
}

.medium-zoom-overlay {
  z-index: 999;
}

.medium-zoom-image--opened {
  z-index: 1000;
}

.hidden-header + .container {
  .product-anchor-tabs {
    top: 0px;
  }
}

.best-product-button {
  width: 100%;
  max-width: $max-container-width;
  margin: 16px auto;
  padding: 0 16px;

  a {
    width: 100%;
    height: 39px;
    font-size: 15px;
    line-height: 1.6;
    font-weight: bold;
    border-radius: 1px;
  }

  &.one-product-button {
    margin-top: 80px;
  }
}
