@import 'variables';

.discoverer-recommend-box {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: 40px 0;
  background-color: white;
  max-width: 420px;
  margin: 0 auto;
  border-top: 0px solid $border-color;

  /* & + & {
  //   border-top: 0.2px solid $border-color; 
  //   margin-top: 0;
   } */

  .product-discoverer-profile {
    border-right: 0;
    flex-basis: 60px;
    text-align: center;
    padding: 5px 0;
  }

  .product-discoverer-image {
    width: 34px;
    height: 34px;
    flex-shrink: 0;
  }

  .product-discoveror-recommend {
    padding: 0 8px;
    flex-basis: calc(100% - 60px);
    text-align: left;
    word-break: break-all;

    .product-discoverer-label {
      font-size: 14px;
      font-weight: 800;

      a {
        color: black;
      }

      .follow-button-wrapper {
        display: inline-block;
        margin-left: 8px;
      }
    }

    .product-discoveror-recommend-date {
      font-size: 11px;
      font-weight: normal;
      color: #9e9fa1;
      margin-left: 3px;
    }

    .product-discoverer-recommend-messagge {
      margin-top: 5px;
      font-size: 14px;
      line-height: 1.5;
    }
  }
}

.recommendbox-wrapper {
  .discoverer-recommend-box:last-of-type {
    border-bottom: 1px solid $border-color;
    margin-bottom: 40px;
  }
}

.one-product-recommend-wrapper {
  max-width: 420px;
  margin: 0 auto;
  border-top: 1px solid $border-color;
}
