@import 'variables';

.giftbox-header-tab {
  width: 100%;
  margin: 0 auto;
  max-width: 768px;
  position: fixed;
  top: $header-height;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  height: 50px;
  z-index: 10;
  background-color: white;
  transition: top 0.2s ease-in-out;

  ul {
    width: 100%;
    margin: 0 auto;
  }

  li {
    width: 50%;
    text-align: center;
  }

  .gift-selected-border {
    font-weight: 700;

    .tab-bottom-dot-icon {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $from-color;

      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  img {
    width: 50px;
    height: 35px;
    // border-radius: 100%;
    display: block;
    margin: 0 auto 5px;
  }

  button {
    width: 100%;
    height: 50px;
    font-size: $font-15;
    font-weight: 800;
    padding: 16px 0;
    position: relative;

    &.gift-selected-border {
      color: $from-color;
    }
  }
}

.giftbox-header-tab-line {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 1px;
    height: 50px;
    background-color: #ddd;
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    right: 0;
  }
}

.gift-unread-notify {
  background-color: #fa3e3e;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  color: white;

  padding-top: 8px;
  font-size: 11px;

  position: absolute;
  top: 8px;
  right: 64px;

  text-align: center;
}

.gift-box-list {
  max-width: 768px;
  margin: 0 auto;
  padding-top: 50px;
}

.hidden-header + .container {
  .giftbox-header-tab {
    top: 0px;
  }
}
