.editor-comp-view-wrapper {
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.editor-comp-label {
  font-size: 15px;
}

.editor-comp-label-text {
  display: flex;
  color: #979797;
  line-height: 1.3;
  font-size: 0.625rem;
  width: 100%;
  margin-top: 8px;
  font-weight: 500;

  span {
    display: block;
    width: 10px;
    height: 10px;
    margin: 1px 5px 0 0;
    border-radius: 100%;
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    color: #64bb46;
    font-size: 0.625rem;
    line-height: 10px;
    text-align: center;
  }
}
