@import 'variables.scss';

@mixin form {
  max-width: $max-container-width;
  width: 100%;
  margin: 0 auto;
  padding: 16px;

  h3 {
    margin: 16px 0;
    font-size: $font-16;
  }
}

@mixin form-checkbox-radio2 {
  label {
    height: 30px;
    line-height: 30px;
    padding-left: 26px !important;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .checkmark {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 20px;
    height: 21px;
    background-color: $white-color;
    border: 1px solid $brand-color;
    box-sizing: border-box;
    transform: translateY(-50%);

    &.provider {
      border: 1px solid $provider-color;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input[type='checkbox']:checked + .checkmark {
    background-image: url('/images/checkbox-checked.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 13px 14px;
  }

  input[type='checkbox']:checked + .provider {
    background-image: url('/images/provider-checkbox-checked.svg');
  }

  input[type='radio'] + .checkmark {
    border: 0;
    background-image: url('/images/radio-unchecked.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 21px;
  }
  /*공급자 상품관리 radio button icon 페이지*/

  input[type='radio']:checked + .checkmark {
    background-image: url('/images/radio-checked.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 21px;
  }
}

@mixin form-checkbox-radio {
  position: relative;
  height: 30px;
  line-height: 30px;
  border: 0;
  padding: 0;

  label {
    padding-left: 26px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: 21px;
      height: 20px;
      box-sizing: border-box;
      transform: translateY(-50%);
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    width: 10;
    height: 10;
    padding: 5px;
    border: none; // solid 2px red;
    top: 0;
    left: 0;
    margin-top: 0;
    position: absolute;
  }

  input[type='checkbox'] + label {
    &:before {
      border: 1px solid $brand-color;
    }
  }

  input[type='radio'] + label {
    &:before {
      background-image: url('/images/radio-unchecked.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }

  input[type='checkbox']:checked + label {
    &:before {
      background-image: url('/images/checkbox-checked.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 13px 14px;
    }
  }

  input[type='radio']:checked + label {
    &:before {
      background-image: url('/images/radio-checked.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 23px 23px;
    }
  }
}

@mixin form-buttons {
  text-align: center;

  .button {
    width: 120px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
