@import 'variables';

.onestore-list-template-wrapper {
  .onestore-list-template-item-profile-image {
    border: 1px solid $from-color;
    border-radius: 50%;
    margin-right: 7px;

    &.provider-connect-store-profile-image {
      border: 0;
    }

    &.is-provider-profile {
      border-color: $provider-color;
    }
  }
}
