@import 'variables';

.from-search-tag-bar {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  margin: 10px 1rem;

  .from-search-tag {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid $button-border-color;
    margin: 5px;

    &.tag-active {
      background-color: #f2f2f2;
    }
  }
}
