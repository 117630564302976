@import 'variables';

.payment-complete-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.payment-complete-loader-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: $header-height;
  background-color: #ffffff;
}

.payment-complete-loader-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 100px;
    height: 100px;
  }
}
