@import 'variables';

.tab {
  height: 50px;
  line-height: 50px;
  text-align: center;

  * {
    color: $text-color;
    font-size: 13.5px;
    font-weight: bold;
  }
}
