@import 'variables';

.pwdedit-header-tab {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  border: 0;

  a {
    font-size: 15px;
  }

  li {
    text-align: center;
    position: relative;

    &.active {
      a {
        color: $from-color;
      }

      .tab-bottom-dot-icon {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $from-color;

        position: absolute;
        bottom: 6px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &.provider-pwdedit-header-tab {
    li.active {
      a {
        color: $provider-color;
      }

      .tab-bottom-dot-icon {
        background-color: $provider-color;
      }
    }
  }
}
