@import 'variables';

.onestore-list-template-wrapper {
  max-width: 420px;
  margin: 0 auto;
  padding: 0 16px;

  .onestore-list-template-label {
    font-weight: 800;
    font-size: 18px;
    padding: 30px 0;
  }

  .onestore-list-template-item {
    display: flex;
    align-items: center;
    padding: 12px 0;

    .onestore-list-template-item-profile-image {
      border: 1px solid $from-color;
      border-radius: 50%;
      margin-right: 7px;

      &.provider-connect-store-profile-image {
        border: 0;
      }

      &.is-provider-profile {
        border-color: $provider-color;
      }
    }

    .onestore-list-template-item-name {
      font-weight: 800;
      font-size: 12px;
      color: #000000;
    }

    .onestore-list-template-item-link-icon {
      margin-left: auto;
    }
  }
}
