@import 'variables';
@import 'breakpoint';
@import 'form';

.register-product-form {
  @include form;
  max-width: 462px;
  padding: 16px;

  &.option-price-list-form {
    background-color: #ffffff;
    margin-top: 16px;
  }

  .form-button {
    font-size: $font-12;
    width: 80px;
    color: $white-color;
    background-color: $provider-color;
  }

  .form-input {
    width: 100%;
  }

  .form-radio {
    border: 0;
    padding: 0;
    width: 80px;
    line-height: 38px;
    //background-color: $page-background-color;
    /*공급자 상품관리 radio button 페이지*/

    @include form-checkbox-radio;
  }

  .form-textarea,
  .form-incentive {
    height: auto;
  }

  /*
  .text-right {
    display: flex;
    margin-bottom: 16px;
    align-items: center;

    .text {
      width: 20px;
      flex-basis: 20px;
      text-align: left;
    }

    .form-group {
      width: calc(100% - 20px);
      margin-bottom: 0;
    }

    .form-input {
      width: calc(100% - 130px);
      text-align: right;
    }
  }
  */

  .invalid-feedback.invalid-feedback-with-label {
    width: 100%;
  }
}

.register-product-editor-form {
  @include form;
  max-width: 430px;
}

.product-search-btn {
  width: 60px;
  top: initial;
  bottom: 8px;
}

.form-incentive-provider {
  input[type='text'] {
    border: 0;
    background: none;
    cursor: default;

    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
}

.product-regist-type-form {
  background-color: $border-color;
}

.product-regist-buttons {
  @include form-buttons;
  background-color: $provider-color;
  width: 100%;
  border-radius: 3px;
}

.register-product-editor-buttons {
  width: 100%;
  max-width: 420px;
  margin: 16px auto;
  padding: 0 25px;

  .button {
    width: 100%;
    height: 50px;
    line-height: 36px;
  }
}

.hidden-header + .container {
  .provider-product-tab,
  .provider-product-tab-2 {
    top: 0px;
  }
}
