@import 'variables';

.order-status {
  width: 100%;
  max-width: $max-container-width;
  margin: 0 auto;

  .from-confirm-button,
  .payment-cancel-button {
    width: 90%;
    height: 48px;
    border-radius: 2.5px;
  }

  .pt-3 {
    font-size: 0.72rem;
    font-weight: normal;
  }

  .order-status-payment-date {
    margin-top: 1rem;
    padding-left: 16px;
    padding-right: 16px;
  }

  .order-status-item {
    padding: 8px;
  }
}

.order-status-info {
  padding: 10px 20px;
  font-size: 1rem;
  line-height: 1.53;
  // border-top: 1px solid $border-color;

  h2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 264px;
    font-size: 1.5em;
    text-align: left;
  }

  li {
    .label {
      font-size: 0.875rem;
      color: $secondary-color;
      display: inline-block;
      width: 25%;
    }
    .value {
      font-size: 0.875rem;
      display: inline-block;
      width: auto;
    }
    .number-value {
      font-size: 0.875rem;
      display: inline-block;
      width: 65%;
      text-align: right;
    }
    .button {
      justify-content: right;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    &.delivery {
      text-align: center;
    }

    &.tracking-detail-status {
      margin-bottom: 0;
    }
  }
}

.order-status-incentive {
  margin-top: 16px;
}

.order-status-label {
  color: $secondary-color;
  display: inline-block;
  width: 80px;
}

.order-status-gift-btn {
  display: block;
  margin: 0 auto;
  color: $from-color;
  //text-decoration: underline;

  &.primary {
    background: none;
    border: 0;
    color: $brand-color;
  }
}

.disable-confirm-button {
  background-color: #a4a5a8;
  color: #ffffff;
  width: 90%;
  height: 48px;
  font-size: 12pt;
  font-weight: bold;
}
