@import 'variables';

.balance-saved-money-status {
  width: 100%;
  max-width: $medium-w;
  margin: 0 auto;
  padding: 30px 20px 45px 20px;

  .balance-saved-money-status-title {
    font-size: 10px;

    span {
      font-size: 10px;
      font-weight: 800;
    }
  }

  .balnce-saved-money-status-price {
    font-size: 30px;
    font-weight: 800;
    color: $from-color;
    margin-top: 20px;
  }
}
