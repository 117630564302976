@import 'variables';

.order-status {
  width: 100%;
  max-width: $max-container-width;
  margin: 0 auto;

  .from-confirm-button,
  .payment-cancel-button {
    width: 90%;
    height: 48px;
  }

  .order-status-payment-date {
    margin-top: 1rem;
    padding-left: 16px;
    padding-right: 16px;
  }

  .order-status-item {
    padding: 8px;
  }
}

.order-status-incentive {
  margin-top: 16px;
}

.order-status-label {
  color: $secondary-color;
  display: inline-block;
  width: 70px;
}

.order-status-label-bold {
  color: $secondary-color;
  display: inline-block;
  width: 70px;
  text-decoration: underline;
}

.order-status-gift-btn {
  display: block;
  margin: 0 auto;
  font-size: 0.85rem;
  font-weight: bold;
  color: $from-color;

  &.primary {
    background: none;
    border: 0;
    color: $from-color;
  }
}

.disable-confirm-button {
  background-color: #a4a5a8;
  color: #ffffff;
  cursor: default;
}

.from-confirm-button-small {
  margin: 8px;
  font-size: 0.8rem;
  width: auto;
  height: 30px;
}

.order-status-item {
  display: grid;
  grid-template-columns: 2fr 3fr 5fr;
  grid-template-rows: auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  align-items: center;
  justify-items: start;
  padding: 10px 10px;
  margin: 20px 10px;
  //border-bottom: 1px solid $border-color;

  grid-template-areas:
    'header header link'
    'image body body'
    'image footer footer'
    '. button button';
  color: $text-color;
}

.order-status-item-canceled {
  opacity: 0.4;
}

.order-status-item-header {
  grid-area: header;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px;
  margin-bottom: 10px;

  span {
    font-size: 0.625rem;
  }

  .main {
    font-weight: 800;
  }

  .sub {
    color: #999898;
  }
}

.order-status-item-link {
  grid-area: link;
  justify-self: end;
  align-self: start;

  button {
    width: 140px;
    height: 20px;
    border-color: #848587;
    background-color: white;
    color: #1d1d1b;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0;
    border-radius: 2.5px;
  }
}

.order-status-item-image {
  grid-area: image;
  justify-self: start;
  align-self: start;
  display: flex;
  width: 55px;
  height: 55px;
  position: relative;
  margin-top: 5px;

  .main-image {
    width: 55px;
    height: 55px;
    border-radius: 4px;
  }

  .sub-image {
    width: 30px;
    height: 30px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -15px;

    border-radius: 50%;
  }

  // object-fit: cover;
  // overflow: hidden;
  // cursor: pointer;
}

.order-status-item-body {
  grid-area: body;
  font-size: $font-12;

  ul {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  li {
    line-height: 1.5;

    a {
      color: black;
    }
    .label {
      font-size: $font-12;
      font-weight: normal;
      color: #999898;
    }

    .value {
      font-size: $font-12;
      font-weight: 800;
    }
  }
}

.order-status-item-footer {
  grid-area: footer;

  .label {
    font-size: $font-12;
    font-weight: normal;
    color: #999898;
  }
  .value {
    font-size: $font-12;
    font-weight: 800;
  }
  button {
    width: 40px;
    height: 20px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: fit;
    background-color: none;

    &.closed {
      background-image: url('/images/more-button-2.svg');
    }

    &.open {
      background-image: url('/images/more-button-close-2.svg');
    }
  }
}
.order-status-item-button {
  grid-area: button;
  button {
    width: 200px;
    height: 20px;
    border-color: #848587;
    background-color: white;
    color: #1d1d1b;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0;
    border-radius: 2.5px;
  }
}
