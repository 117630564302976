@import 'variables';

.overlap-picture-image {
  width: 55px;
  height: 55px;
  margin-right: 23px;
  position: relative;

  .overlap-picture-main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .overlap-picture-sub-image {
    width: 30px;
    height: 30px;
    object-fit: cover;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -15px;

    border: 1px solid $provider-color;
    border-radius: 50%;

    &.sub-border-from {
      border-color: $from-color;
    }

    &.sub-border-provider {
      border-color: $provider-color;
    }

    &.no-image-border {
      border: 0;
    }
  }
}
