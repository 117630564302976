@import 'variables';
@import 'form';

.signin-form {
  &.kakao {
    @include form;
  }
}

.kakao-button {
  width: 100%;
  height: 50px;
  border: 0;
  font-size: $font-18;

  &:disabled {
    background-color: $gray-200;
    color: $secondary-color;
  }

  &.first {
    margin: 100px 0 20px;
  }

  &.able {
    background-color: $warning-color;
    color: #392020;
  }
}
