@import 'breakpoint';
@import 'variables';

.order-confirm-view {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 999;
}

.order-confirm-box {
  position: relative;
  max-width: 420px;
  height: 100%;
  margin: 0 auto;
}

.order-confirm-in-box {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  padding-top: 20px;
}
.confirm-close-img-link {
  display: block;
  width: 40px;
  height: 40px;
}
.confirm-close-img {
  position: absolute;
  width: 40px;
  top: 5px;
  right: 3%;
  z-index: 1;
  // left: 50%;
  // transform: translateX(-50%);
}

.confirm-letter-box {
  background-color: white;
  position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  left: 50%;
  transform: translateX(-50%);
  width: 84%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  .gray-text {
    color: #bbbbbb;
    margin-bottom: 9px;
    font-weight: bold;
    font-size: 12px;
  }

  .bold-black-text {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 15px;
  }

  .confirm-letter-img {
    border-radius: 8px 8px 0 0;
    text-align: center;
    overflow: hidden;
    padding-top: 14px;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      // object-position: top;
      vertical-align: middle;
    }
  }

  .confirm-letter-id-box {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;

    .gray-text {
      color: #bbbbbb;
      margin-bottom: 9px;
      font-weight: bold;
    }

    .bold-black-text {
      font-weight: 800;
      font-size: 16px;
      margin-bottom: 15px;
    }

    .letter-small-text-box {
      margin-bottom: 25px;

      .letter-small-text {
        &.opt-bold {
          span {
            color: #000000;
            font-weight: 800;
          }
        }

        border-bottom: 0.1px solid #f6f6f6;

        padding-top: 9px;
        padding-bottom: 9px;

        span {
          font-size: 12px;
          color: #bbbbbb;
        }

        .letter-small-right {
          float: right;
          color: #4a4a4a;
          line-height: 1.3;
        }
      }
    }
  }
}
.confirm-letter-text-box {
  position: absolute;
  /*
  bottom: 85px;
  */
  bottom: 25px;

  padding-left: 55px;
  padding-right: 55px;
  padding-top: 20px;
  width: 100%;
  font-size: 12px;

  @include max-width-small-phone {
    bottom: 45px;
  }

  .confirm-letter-alert {
    font-size: 10px;
    color: #d0021b;
    margin-bottom: 15px;
  }

  .confirm-letter-btn {
    width: 100%;
    height: 54px;
    border-radius: 8px;
    line-height: 2.5;
    color: #ffffff;
    background-color: #37e211;
    font-weight: 800;
  }
}

.confirm-letter-envelope {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.order-confirm-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
