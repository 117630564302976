@import 'variables';

.tabs {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  border-bottom: 0px solid #d1d1d1;

  /*
  li:nth-child(1) {
    &.active {
      border-bottom: 2px solid $warning-color;
    }
  }
  li:nth-child(2) {
    &.active {
      border-bottom: 2px solid $brand-color;
    }
  }
  */

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.provider-product-tab {
  width: 100%;
  margin: 0 auto;
  max-width: $max-container-width;
  position: fixed;
  top: $header-height;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  height: 50px;
  z-index: 10;
  background-color: white;
  transition: top 0.2s ease-in-out;

  & + div {
    padding-top: 50px;
  }

  li {
    width: 50%;
  }

  li:first-child {
    &.active {
      border-bottom: 2px solid $warning-color;
    }
  }

  li:nth-child(2) {
    &.active {
      border-bottom: 2px solid $brand-color;
    }
  }

  &.modify-product-tab {
    max-width: 462px;

    li {
      &.active {
        border-bottom: 2px solid $brand-color;
      }
    }
  }
}

.provider-product-tab-2 {
  width: 100%;
  margin: 0 auto;
  max-width: $max-container-width;
  position: fixed;
  top: $header-height;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  height: 65px;
  z-index: 10;
  background-color: white;
  transition: top 0.2s ease-in-out;
  padding-right: 15px;

  & + div {
    padding-top: 80px;
  }

  li {
    width: 50%;
    height: 69px;
    line-height: 69px;
    position: relative;

    a {
      font-weight: 800;
      font-size: 14.5px;

      &:hover {
        color: $provider-color;
      }
    }

    &.active {
      a {
        color: $provider-color;
      }

      .tab-bottom-dot-icon {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $provider-color;

        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &.modify-product-tab {
    max-width: 462px;

    li {
      &.active {
        border-bottom: 0px solid $provider-color;
      }
    }
  }
}
