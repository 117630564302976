/* brand color */
$brand-color: #37e211;
$from-color: #37e211;
$from-light-color: #62ff11;
$provider-color: #f68b1f;
$page-background-color: #f8f8f9;
$yellow-support-color: #ffde00;

/* event */
$x-mas-red-color: #ec1c24;
$x-mas-brown-color: #c49a6c;
$sulnal-blue-color: #57b6ea;

/* bootstrap override */
$danger-color: #ff0000;
$secondary-color: #979797;
$warning-color: #ffdd45;

$white-color: #fff;
$black-color: #000;

$text-color: #212529;
$border-color: #f5f4f4;
$button-border-color: #d1d1d1;
$placeholder-color: #d1d2d3;

$gray-200: #f0f0f0;
$gray-250: #ebebeb;
$gray-300: #cfd1d2;
$gray-800: #656565;

$header-height: 56px;
$footer-height: 58px;
$iphone-x-add-height: 33px;

$colors: (
  white: $white-color
);

$theme-colors: (
  primary: $brand-color,
  secondary: $secondary-color,
  danger: $danger-color,
  warning: $warning-color,
  from-color: $from-color,
  x-mas-red: $x-mas-red-color,
  x-mas-brown: $x-mas-brown-color,
  sulnal-blue: $sulnal-blue-color
);

/* breakpoints */
$x-small-w: 320px;
$small-w: 575px;
$medium-w: 768px;
$large-w: 992px;
$x-large-w: 1200px;
$xx-large-w: 1400px;
$wide-w: 1600px;
$x-wide-w: 1920px;

$max-container-width: 420px;

$breakpoints: (
  x-small: $x-small-w,
  small: $small-w,
  medium: $medium-w,
  large: $large-w,
  x-large: $x-large-w,
  xx-large: $xx-large-w,
  wide: $wide-w,
  x-wide: $x-wide-w
);

$font-20: 1.25rem; // 20px
$font-18: 1.125rem;
$font-16: 1rem; // 16px
$font-15: 0.9375rem; //15px;
$font-14: 0.875rem; // 14px
$font-13: 0.8125rem;
$font-12: 0.75rem;
$font-10: 0.625rem;

$font-family-R: Nanum Sqaure;
$font-family-L: Nanum SqaureL;
$font-family-B: Nanum SqaureB;
$font-family-EB: Nanum SqaureEB;
