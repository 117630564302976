.footer-menu-profile {
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-menu-profile-flag {
  position: absolute;
  width: 9px;
  height: auto;
  top: 0px;
  right: 0px;
}
