@import 'variables';

.recommend-edit-box-wrapper {
  flex-wrap: wrap;

  .recommend-edit-box-edit-close-button {
    border: 1px solid $button-border-color;
    margin-right: 8px;
    min-width: 58px;
    color: #808080;
  }

  .recommend-edit-box-edit-button {
    color: $from-color;
    font-size: 10px;
    padding: 10px 0;
  }

  .recommend-edit-box-edit-submit-button,
  .recommend-edit-box-edit-close-button {
    min-width: 58px;
    padding: 0;
    font-size: 12px;
    font-weight: bold;
  }

  .recommend-edit-box-edit-control {
    width: 100%;
    padding: 0 16px;
  }
}
