.search-loader {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #fafafa;
  height: calc(100vh - 186px);
  margin-bottom: -43px;

  img {
    margin: 0 auto;
  }
}

.search-loader-gif {
  width: 60px;
  height: 60px;
}
