@import 'variables';
$input-height: 42px;
$border-radius: 8px;
$button-width: 65px;
/*공급자 상품관리 페이지*/

$label-width: 95px;

.form-group {
  position: relative;
  margin-bottom: 30px;
}

.form-headline {
  margin: 1.5rem 0;
  font-size: $font-16;
  font-weight: 800;
}

.form-label {
  width: $label-width;
  padding: 10px 0;
  line-height: 1.5;
  font-size: 0.8125rem;
  font-weight: 800;
  color: #2d2a2a;
  word-break: keep-all;
}

.form-description {
  width: 100%;
  line-height: 1.5;
  padding-bottom: 10px;

  span {
    font-size: 12px;
    color: #979797;
  }
}

.form-control {
  width: 100%;
  height: $input-height;
  line-height: $input-height;
  font-size: $font-14;
  padding: 0 0.75rem;

  &.form-input {
    width: calc(100% - #{$label-width});
    border-radius: 1px;
  }
}

.form-button {
  position: absolute;
  padding: 0;
  width: 50px;
  height: 25px;
  line-height: 25px;
  border-radius: 3px;
  top: 8px;
  right: 5px;
  font-size: $font-12;

  &.form-input-in-button {
    background-color: $from-color;
    width: 65px;
    height: 22px;
    line-height: 22px;
    color: $white-color;
    border: 0;
    margin-top: 2px;
  }
}

.invalid-feedback {
  width: 100%;
  border-radius: $border-radius;
  float: left;
  text-align: left;
  font-weight: bold;

  &.invalid-feedback-with-label {
    width: calc(100% - #{$label-width});
    float: right;
  }
}

/* 로그인 버튼 */
.render-field-login-btn {
  padding: 0;
  width: 100px;
  height: 25px;
  line-height: 25px;
  font-size: $font-12;
  text-align: center;
  border-radius: 5px;
}

/* validation 체크 */
.custom-valid-feedback {
  width: calc(100% - #{$label-width});
  float: right;
  margin-top: 0.25rem;
  font-size: 80%;
}

/* 로딩바 */
.async-validating {
  position: absolute;
  z-index: 200;
  height: 15px;
  width: 15px;
  top: 13px;
  right: 10px;
  filter: invert(100%);
}
