@import 'variables';

.balnce-save-money {
  width: 100%;
  max-width: $medium-w;
  margin: 0 auto;
}

.balnce-save-money-list {
  padding-bottom: 55px;

  .ReactVirtualized__Table__headerRow {
    background-color: #f0f0f0;
  }

  .ReactVirtualized__Table__headerColumn {
    text-align: center;

    &:last-of-type {
      border-right: 0;
    }
  }
}

.balnce-save-money-list-item {
  border-bottom: 1px solid $border-color;

  .ReactVirtualized__Table__rowColumn {
    text-align: center;
    &:last-of-type {
      border-right: 0;
    }
  }

  * {
    font-size: $font-12;
    text-overflow: unset;
    white-space: initial;
    line-height: 1.3;
  }
}
