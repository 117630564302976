@import 'variables';

.dynamic-profile-picture {
  position: relative;
  width: 47px;
  height: 47px;

  .dynamic-profile-picture-image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
}

// StoreProfilePicture.scss
.onestore-profile {
  position: relative;
  width: 61px;
}

.onestore-profile-cover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.onestore-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  /*
  mask: url('/images/profile-mask.svg');
  */
}

.onestore-profile-flag {
  position: absolute;
  top: 0;
  left: 28px;
  z-index: 1;
}

.onestore-default-profile-cover {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.onestore-default-profile-text {
  position: absolute;
  color: #ffffff;
  line-height: 1.3;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  text-align: center;
  width: 100%;
  height: 70px;
  line-height: 1.3;
  padding-top: 20px;

  .no {
    font-size: $font-12;
  }

  .store,
  .provider {
    display: block;
    font-size: $font-14;
    font-weight: 600;
    text-align: center;
    text-align: -webkit-center;
  }

  .store {
    overflow: hidden;
    white-space: nowrap;
    word-wrap: normal;
    text-overflow: ellipsis;
  }

  .provider {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 1.3rem;
    height: 2.6rem; /* height = line-height * 줄수 : 비wekkit 계열 */
  }
}

.onestore-setting {
  border: 0;
  display: block;
  position: absolute;
  z-index: 3;
  right: -5px;
  bottom: -5px;
  width: 26px;
  height: 26px;
  // background-image: url('/images/setting-btn-black-2.svg');
  background-image: url('/images/footer/footer-setting-icon-2.svg');
  background-size: contain;
  text-indent: -1000rem;

  /*
  
  &.provider {
    background-image: url('/images/setting-btn.svg');
  }

  */
}
