@import 'variables';
@import 'breakpoint';

.search-category-view {
  margin-top: 0px;

  .search-recommend-menu {
    margin-bottom: 8px;
  }

  .search-recommend-menu-title {
    color: #979797;
    background-color: white;
    padding: 26px;
    font-weight: 800;
    color: $black-color;
    display: flex;
    align-items: flex-end;

    img {
      margin-right: 16px;
      height: 16px;
    }

    span {
    }
  }
}

.search-category-item-wrapper {
  /*
  background-color: white;

  
  @include max-width-phone {
    display: flex;
    flex-wrap: wrap;
  }
  */

  /* 위에 검색처럼 수정 */
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    height: 5px;
    background-color: $gray-200;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $gray-300;
  }

  margin: 10px 0;
}

.search-category-item-text-wrapper {
  padding: 0 25px;

  .search-category-item-link {
    padding: 15px 0;
    display: flex;

    .search-category-item-link-text {
      font-weight: 800;
      font-size: 12px;
    }

    .search-category-item-link-icon {
      margin-left: auto;
    }
  }
}

.search-category-item {
  display: inline-block;
  /*
  max-width: 150px;
  width: 19%;
  margin: 3%;
  margin-bottom: 5%;
  */

  width: 19%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 16px;
  margin-bottom: 16px;

  max-width: 60px;

  @include max-width-phone {
    /*
    margin: 3%;
    */
    margin: calc((25% - 60px) / 2);
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .search-category-item-image {
    border-radius: 50%;
    width: 100%;

    &.category-item-image-noradius {
      border-radius: initial;
    }
  }

  .search-category-item-name {
    font-size: 12px;
    text-align: center;
    margin-top: 12px;
    color: #000000;
    height: 24px;
  }
}

.slide-search-category-item {
  display: inline-block;

  width: 129px;
  margin-right: 15px;
  margin-top: 16px;
  margin-bottom: 16px;

  &:first-of-type {
    margin-left: 15px;
  }

  .search-category-item-image {
    border-radius: 50%;
    width: 100%;

    &.category-item-image-noradius {
      border-radius: initial;
    }
  }

  .search-category-item-name {
    font-size: 12px;
    text-align: center;
    margin-top: 12px;
    color: #000000;
    height: 24px;
  }
}
