@import 'variables';

.tracking-status {
  display: flex;
  margin: 5px 0px;
  line-height: 1.3;
  font-size: $font-12;
  font-weight: 800;

  img {
    width: 100px;
    object-fit: none;
    display: block;
  }

  .label {
    font-weight: bold;
    color: #999898;
  }
  .value {
  }

  &.tracking-text {
    text-align: center;
    margin: 20px;
  }
}

.tracking-detail-status {
  display: flex;
  min-height: 30px;
  margin-bottom: 0;
  // align-items: center;
  border-bottom: 1px solid $border-color;
  flex-wrap: nowrap;
  align-content: space-between;
  margin: 0px 0px;

  &.header {
    border-top: 1px solid $border-color;
    border-bottom: 2px solid $border-color;
    font-weight: 600;
    color: #8e8d8d;
  }

  &.tracking-detail {
    margin-top: 10px;
  }

  &.last-detail {
    background-color: rgba($color: $brand-color, $alpha: 0.3);
  }

  div {
    height: 100%;
    width: 100%;
    padding: 8px;
    line-height: 1.3;
    min-height: 100%;
    text-align: left;
    font-size: 0.625rem;

    &.time {
      width: 40%;
      color: #8e8d8d;
    }

    &.where {
      width: 30%;
    }

    &.kind {
      width: 30%;
    }
  }
}
