@import 'variables';

$button-width: 90px;
$text-height: 20px;
$padding: 3px;

.discover-detail {
  display: grid;
  grid-template-columns: minmax(320px, 1fr);
  /* grid-template-columns: 1fr repeat(3, minmax(110px, 3fr)) 1fr; */
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    'discover-header'
    'discover-image'
    'discover-recommendation';
  row-gap: 20px;
  align-items: center;
  justify-items: center;
  margin: 20px auto;
  min-width: 320px;
  max-width: 800px;
}

.discover-title {
  grid-area: discover-header;
}

.discover-image {
  grid-area: discover-image;
}

.discover-recommendation {
  grid-area: discover-recommendation;
  display: flex-no-wrap;
  width: 100%;
}

.product-header-title {
  font-size: 1rem;
  font-weight: bold;
  color: $from-color;
  text-align: center;
}

.product-image-slide {
  width: 100%;
  height: auto;
  text-align: center;
  overflow: hidden;
  .slick-dots {
    bottom: 16px;
  }
}

.product-image-slide-item {
  position: relative;
  width: 420px;
  height: 420px;
  object-fit: cover;
  margin: 0 auto;
}

.discover-support-button {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-size: 14.5px;
  font-weight: bold;
  text-align: center;
  background-color: $white-color;
  border-color: $black-color;
  color: $black-color;
  width: 320px;

  &.btn {
  }

  &.btn:not(:hover) {
  }

  &.btn-outline-primary {
  }

  .discover-support-button-image-icon {
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 30px;
    height: 17px;
    margin-right: 2px;
    vertical-align: middle;
  }
}
