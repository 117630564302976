@import 'variables';
@import 'form';
@import 'breakpoint';

/* 가격 정보 */
.payment-price-info {
  padding: 16px;
  border-top: 9px solid #f2f2f2;
  border-bottom: 9px solid #f2f2f2;

  .payment-price-info-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 29px;
  }

  ul {
    margin: 0 auto 5px;
  }

  .form-control {
    background-color: #f6f6f6;
  }
}

.payment-price-incentive {
  font-size: $font-12;
  color: $secondary-color;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  * {
    font-size: $font-12;
  }
}

.payment-price-incentive-info {
  border-top: 1px dashed $border-color;
}

/* 가격 */
.payemnt-delivery-text {
  font-size: $font-12;
  margin-left: 10px;

  @include max-width-small-phone {
    margin-top: 5px;
    margin-left: 0;
    display: block;
  }
}

.payment-privacy {
  height: 69px;
  border: 1px solid $border-color;
}

.payment-total-price {
  display: flex;
  font-size: $font-12;
  color: $secondary-color;

  &.count {
    margin-bottom: 5px;
  }

  &.payment-total-total-price {
    font-size: 14px;
    color: black;

    .font-weight-bold {
      font-size: $font-18;
      font-weight: 800;
    }
  }

  .font-weight-bold {
    margin-left: 5px;
    font-size: $font-14;
    font-weight: bold;
    color: #000000;
  }
}

.payment-total-price-number {
  margin-left: auto;
  font-size: $font-16;
}

.payment-free-delivery {
  color: $from-color;
  font-weight: bold;
  font-size: 10px;
}
