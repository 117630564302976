@import 'breakpoint';
@import 'variables';
@import 'font';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: $font-14;
  vertical-align: baseline;
  background: transparent;
}

html,
body {
  height: 100%;
  font-size: 16px;
}

body {
  font-family: 'Nanum Sqaure', sans-serif;
  // -ms-user-select: none;
  // -moz-user-select: -moz-none;
  // -khtml-user-select: none;
  // -webkit-user-select: none;
  // user-select: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-weight: 400;
}

ol,
ul,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**/
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

a {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

input,
select,
button {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;
  border: none;
  outline: none !important;
}

img {
  border: none;
  vertical-align: top;
}

button {
  display: inline-block;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  line-height: 1;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  vertical-align: middle;
}

i,
em,
address {
  font-style: normal;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

/* Form Reset */
input,
label,
select,
button,
textarea {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  font-size: 1em;
}

input,
button,
textarea {
  box-sizing: border-box;
  -webkit-appearance: none;
}

input[type='password'] {
  font-family: initial;
}

button,
input[type='reset'],
input[type='button'],
input[type='submit'],
input[type='file'] {
  overflow: visible;
  // -webkit-appearance: button;
  cursor: pointer;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::placeholder,
input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder,
input[type='number']::placeholder,
input[type='tel']::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholder-color;
  font-size: $font-12;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $placeholder-color;
  font-size: $font-12;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $placeholder-color;
  font-size: $font-12;
}

#root {
  height: 100%;
}

.os-container {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: $header-height;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;

  @include min-width-desktop {
    width: 950px;
  }

  &.op-container {
    max-width: 480px;
  }
}

/* loading */
.loading-roller {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;

  span {
    display: inline-block;
    animation: loading-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 15px 15px;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 2px;
      background: #fff;
      margin: -3px 0 0 -3px;
    }

    &:nth-child(1) {
      animation-delay: -0.036s;
    }

    &:nth-child(1):after {
      top: 30px;
      left: 30px;
    }

    &:nth-child(2) {
      animation-delay: -0.072s;
    }
    &:nth-child(2):after {
      top: 54px;
      left: 45px;
    }
    &:nth-child(3) {
      animation-delay: -0.108s;
    }
    &:nth-child(3):after {
      top: 57px;
      left: 39px;
    }
    &:nth-child(4) {
      animation-delay: -0.144s;
    }
    &:nth-child(4):after {
      top: 58px;
      left: 32px;
    }
    &:nth-child(5) {
      animation-delay: -0.18s;
    }
    &:nth-child(5):after {
      top: 57px;
      left: 25px;
    }
    &:nth-child(6) {
      animation-delay: -0.216s;
    }
    &:nth-child(6):after {
      top: 54px;
      left: 19px;
    }
    &:nth-child(7) {
      animation-delay: -0.252s;
    }
    &:nth-child(7):after {
      top: 50px;
      left: 14px;
    }
    &:nth-child(8) {
      animation-delay: -0.288s;
    }
    &:nth-child(8):after {
      top: 45px;
      left: 10px;
    }
  }
}

/* 로딩바 */
.loading-spinner {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  animation: loading-roller 3s infinite;
}

@keyframes loading-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

select,
select.form-control {
  padding: 0.375rem 1.875rem 0.375rem 0.75rem;
  background-image: url('/images/select-dropdown-btn.svg');
  background-repeat: no-repeat;
  background-position: right 15px center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 42px;
  border-radius: 2px;
  line-height: 1.3;
}
/*공급자 상품관리 옵션 순서*/
select::-ms-expand {
  display: none;
}
