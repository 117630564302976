@import 'variables';
@import 'breakpoint';
@import 'form';

.register-product-price {
  width: calc(100% - 100px);

  li {
    display: flex;
  }

  .form-group {
    width: 50%;
  }

  .form-price {
    input {
      text-align: right;
    }
  }
}

.product-regist-price-button {
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  border-radius: 100%;
  text-indent: -1000rem;

  &.add {
    margin-left: 5px;
    background-color: $brand-color;
    background-image: url('/images/plus-btn.svg');
    border-color: $brand-color;
  }

  &.remove {
    border-radius: 100%;
    text-indent: -1000rem;
    background-color: $danger-color;
    background-image: url('/images/minus-btn.svg');
    border-color: $danger-color;
  }

  &.toggle {
    width: 32px;
    height: 32px;
    margin-left: auto;
    background-image: url('/images/insentive-btn.svg');
    background-repeat: no-repeat;
    background-size: cover;

    &[value='true'] {
      background-image: url('/images/insentive-btn2.svg');
    }
  }
}
