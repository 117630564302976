@import 'variables';
@import 'form';

$label-width: 100px;

.discover-form {
  @include form;
}

.discover-form-buttons {
  .button {
    width: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 3px;
  }
}
