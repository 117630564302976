@import 'variables';

.app-check-modal {
  .modal-content {
    background-color: transparent;
    border: 0;
  }

  .modal-close-button {
    display: none;
  }

  .app-check-big-text {
    span {
      font-weight: bold;
      font-size: 24px;
      color: #ffffff;
      line-height: 1.2;
    }
  }

  .app-connect-button {
    margin-top: 16px;
  }

  .web-connect-button {
    margin-top: 16px;
    color: #ffffff;
    text-decoration: underline;
  }
}

.modal-backdrop.show.opacity-7 {
  opacity: 0.7;
}

.event-modal-button {
  text-align: left;
  padding-top: 4px;
  padding-left: 10px;

  label {
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
    padding-left: 26px !important;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .checkmark {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: 1px solid #000000;
    box-sizing: border-box;
    transform: translateY(-50%);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input[type='checkbox']:checked + .checkmark {
    background-image: url('https://cdn.from.kr/transparent-checkbox-checked.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }

  input[type='checkbox']:checked + .provider {
    background-image: url('/images/provider-checkbox-checked.svg');
  }

  button {
    width: 100%;
  }
}
