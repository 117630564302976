@import 'variables';
@import 'form';
@import 'breakpoint';

.payment-alert {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}

.payment-alert-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $white-color;
  width: 320px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  z-index: 12;
  border-radius: 8px;
}

.payment-alert-backdrop {
  position: fixed;
  background-color: $black-color;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 11;
}
