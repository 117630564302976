@import 'variables';

$button-width: 35px;
$button-right: 10px;

.text-input {
  position: relative;
}

.text-input-ghost {
  position: absolute;
  display: block;
  width: 100%;
  padding: 10px 16px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  line-height: 1.5;

  .text-input-with-button & {
    padding: 10px $button-width + $button-right 10px 16px;
  }
}

.text-input-textarea {
  display: block;
  width: 100%;
  min-height: 35px;
  padding: 10px 16px;
  outline: none;
  line-height: 1.5;
  box-shadow: none;
  border: 0;
  overflow: hidden;
  transition: height 0.2s ease;

  .text-input-with-button & {
    padding: 10px $button-width + $button-right 10px 16px;
  }
}

.text-input-button {
  position: absolute;
  top: 50%;
  right: $button-right;
  transform: translateY(-50%);
}
