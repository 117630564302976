@import 'variables';

.stop-selling-modal {
  .modal-header-image {
    .center-circle-image {
      &.center-circle-image-border-from {
        border-color: $provider-color;
      }
    }
  }

  .modal-header-text {
    color: $provider-color;
  }
}
