@import 'variables';

.kakao-login {
  display: block;
  background-color: #ffe609;
  color: #392120;
  text-align: center;
  border-radius: 3px;

  img {
    margin-right: 8px;
  }
}
