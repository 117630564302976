@import 'variables';

.ordergift-list {
  width: 100%;
  max-width: $medium-w;
  margin: 0 auto;
  padding: 12px;

  .ordergift-list-item-wrapper {
    border-bottom: 1px solid #f3f3f3;
  }

  .ordergift-list-item {
    margin: 12px 0;

    &.is-confirmed-item {
      background-color: #f9f9fa;
      opacity: 0.2;
    }

    .ordergift-list-item-small-text {
      margin-right: 5px;
      color: black;
      font-size: 10px;
    }

    .ordergift-list-item-point-icon {
      margin-right: 5px;
    }

    .ordergift-list-item-header {
      background-color: #ffffff;
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: center;

      .ordergift-list-item-header-type {
        margin-right: 5px;
        font-weight: 800;
        font-size: 10px;
        color: #000000;
      }

      .ordergift-list-item-header-right {
        margin-left: auto;
        display: flex;
        align-items: center;

        .status-ordered-recv {
          background-color: $from-color;
          color: #ffffff;
          font-weight: bold;
          font-size: 11.6px;
          width: 165px;
          padding: 1px 0;
        }

        .status-ordered-send {
          background-color: #fee509;
          color: #000000;
          font-weight: bold;
          font-size: 11.6px;
          width: 165px;
          padding: 1px 0;
          position: relative;

          img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 8px;
            width: 11px;
          }
        }
      }
    }

    .ordergift-list-content {
      padding-top: 16px;
      padding: 16px 15px 20px 15px;
      background-color: #ffffff;
      position: relative;

      .ordergift-list-content-top {
        display: flex;
        align-items: center;

        .ordergift-list-content-top-image {
          width: 25px;
          height: 25px;
          background-size: cover;
          background-position: center;
          border-radius: 50%;
        }

        .ordergift-list-content-top-name {
          margin-left: 10px;
          color: black;

          span {
            font-weight: bold;
          }
        }

        .ordergift-list-content-top-button {
          margin-left: auto;

          button {
            background-color: #ffe609;
            border-color: #ffe609;
            color: #000000;
            font-size: 12px;
            border-radius: 10px;
            padding: 6px 8px;

            img {
              margin-left: 8px;
              vertical-align: middle;
            }
          }
        }
      }

      .ordergift-list-content-product-info {
        display: flex;

        .ordergift-list-content-product-image {
          width: 55px;
          height: 55px;
          background-size: cover;
          background-position: center;
          border-radius: 5px;
        }

        .ordergift-list-content-product-info-text {
          width: calc(100% - 90px);

          .ordergift-list-content-product-name {
            font-size: 12px;
            font-weight: bold;
            color: black;
            line-height: 1.4;
            word-break: keep-all;
            margin-bottom: 8px;
          }
        }
      }

      .ordergift-list-content-option-info {
        color: #9b9b9b;
        margin-bottom: 13px;
      }

      .ordergift-list-content-optionprice {
        display: flex;

        .ordergift-list-content-price-info {
          margin-left: auto;
          display: flex;
          align-items: flex-end;
          margin-bottom: 13px;
          color: black;

          span {
            font-weight: 800;
          }
        }
      }

      .ordergift-recv-button {
        margin-top: 16px;
        width: 100%;
        height: 50px;
        line-height: 36px;
        font-size: 18px;
        font-weight: bold;
      }

      .ordergift-list-content-overlay {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        img {
          opacity: 0.4;
        }
      }
    }
  }
}
