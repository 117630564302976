@import 'variables';

.guide-wrapper {
  margin-bottom: -110px;
}

.guide-slide {
  width: 90%;
  max-width: 500px;
  text-align: center;
  overflow: hidden;
  margin: 0 auto;

  .slick-arrow {
    width: 32px;
    height: 56px;
    text-indent: -1000rem;
    z-index: 1;

    &.slick-prev {
      left: 13px;
      background-image: url('/images/slide-prev-btn.png');
    }

    &.slick-next {
      right: 13px;
      background-image: url('/images/slide-next-btn.png');
    }
  }
}

.guide-slide-item {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(
    1022 / 753 * 100%
  ); /* calc (이미지 높이 ÷ 이미지 가로 × 100 %) */
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
}

.slide-index {
  color: #ffffff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.guide-index {
  position: absolute;
  right: 10px;
  top: 15px;
  padding: 8px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.1);
}
