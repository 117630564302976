@import 'variables';
$close-color: #4a4a4a;

.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: $font-16;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.primary {
    background-color: $from-color;
    border-color: $from-color;
    color: $white-color;
  }

  &.from {
    background-color: $from-color;
    border-color: $from-color;
    color: $white-color;
  }

  &.w-from {
    background-color: $white-color;
    border-color: $from-color;
    color: $from-color;
  }

  &.close-from {
    background-color: $white-color;
    border-color: $button-border-color;
    color: $from-color;
  }

  &.provider {
    background-color: $provider-color;
    border-color: $provider-color;
    color: $white-color;
  }

  &.close-provider {
    background-color: $white-color;
    border-color: $button-border-color;
    color: $provider-color;
  }

  &.close-dark {
    background-color: $close-color;
    border-color: $close-color;
    color: $white-color;
  }

  &.red {
    background-color: $danger-color;
    border-color: $danger-color;
    color: $white-color;
  }

  &.gray {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $white-color;
  }

  &.yellow {
    background-color: $warning-color;
    color: #656565;
  }

  &.orange {
    background-color: $provider-color;
    border-color: $provider-color;
    color: $white-color;
  }

  &.line-primary {
    border: 0;
    color: $from-color;
  }

  &.line-from {
    border: 0;
    color: $from-color;
  }

  &:disabled {
    opacity: 0.65;
  }
}

.row-button-group {
  display: flex;
  vertical-align: middle;

  .button {
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    flex: 1;

    &:first-child {
      margin-right: 2%;
    }
  }

  a {
    vertical-align: middle;
    line-height: 2;
  }
}

.col-button-group {
  .button {
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    height: 40px;

    &.btn-close {
      // color: $from-color;
      background-color: white;
      border: 1px solid $button-border-color;
    }

    &:last-of-type {
      margin-top: 1rem;
    }
  }

  a {
    line-height: 1.8;
  }
}
