@import 'variables';

.received-gift-delivery-form {
  width: 100%;
  max-width: $max-container-width;
  margin: 0 auto;
  margin-bottom: 50px;

  .info-form {
    border-bottom: 9px solid #ebebeb;
  }
}

.recv-title {
  span {
    font-size: 15px;
  }
}

.gift-recv-signup-agreement-form {
  padding: 16px;
}
