.tutorial-intro-form {
  margin-top: 100px;
  .tutorial-start-btn {
    width: 190px;
    height: 50px;
    font-size: 19.5px;
  }
}
.tutorial-intro-text {
  color: #74c358;
  line-height: 25px;
  div {
    font-size: 17.5px;
  }
}
