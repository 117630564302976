@import 'variables';

.shipping-item-wrapper {
  padding: 35px 25px 18px 25px;
  border-bottom: 1px solid $border-color;

  &.is-cancel-shipping-item {
    opacity: 0.4;
  }

  .shipping-item-top {
    display: flex;
    margin-bottom: 19px;

    .shipping-item-date-uid {
      line-height: 1.5;

      .shipping-item-date {
        font-weight: 800;
        font-size: 10px;
      }

      .shipping-item-uid {
        font-size: 10px;
        color: #999898;
      }
    }

    .shipping-item-button-wrapper {
      margin-left: auto;
      display: flex;
      flex-direction: column;

      button {
        width: 165px;
        height: 20px;
        background-color: $provider-color;
        color: white;
        font-size: 12px;
        font-weight: bold;
        padding: 0;
        border-radius: 2.5px;

        &.shipping-item-modify {
          border-color: $provider-color;
          background-color: white;
          color: $provider-color;
        }

        &.shipping-item-cancel-order {
          border-color: #848587;
          background-color: white;
          color: #1d1d1b;
        }
      }

      .shipping-item-delivery-text {
        font-size: 10px;
        color: #939292;
        text-align: center;
        line-height: 1.5;
        margin-top: 13px;
      }
    }
  }

  .shipping-item-content {
    display: flex;

    .shipping-item-image {
      width: 55px;
      height: 55px;
      margin-right: 23px;
      position: relative;
      flex-basis: 55px;

      .shipping-item-main-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }

      .shipping-item-sub-image {
        width: 30px;
        height: 30px;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -15px;

        border-radius: 50%;
      }
    }

    .shipping-item-info {
      flex-basis: calc(100% - 55px - 23px);

      font-size: 12px;
      line-height: 1.5;

      .shipping-item-info-text {
        font-weight: bold;

        span {
          font-weight: normal;
          color: #999898;
        }
      }

      .shipping-item-info-bold-text {
        font-weight: 800;

        span {
          font-weight: normal;
          color: #999898;
        }
      }
    }
  }
}
