@import 'variables';

.onestore-follow-link-wrapper {
  max-width: 420px;
  margin: auto;
  margin-bottom: 2rem;

  a {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $border-color;
    padding: 20px 16px 15px 16px;

    color: black;
    font-size: 14px;
    font-weight: 800;

    img {
      margin-left: auto;
      width: 86px;
      height: 52px;

      &.follow-link-small-image {
        height: 47px;
      }

      &.onestore-setting-link-icon-order {
        height: 47px;
        width: 18px;
      }
    }
  }
}
