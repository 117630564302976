@import 'variables';

$save-color: #167ffc;
$used-color: #fd3d39;

.saved-money-list {
  width: 100%;
  max-width: $medium-w;
  margin: 0 auto;

  .saved-money-item {
    background-color: #fff;
    padding: 20px 15px;
    margin: 10px 0;
    border-top: 8px solid $border-color;

    .saved-money-item-small-text {
      font-size: 10px;
      color: #979797;
      margin-right: 5px;
    }

    .saved-money-item-point-icon {
      margin-right: 5px;
    }

    .saved-money-item-header {
      padding-bottom: 10px;
      display: flex;
      align-items: center;

      .saved-money-item-header-type {
        border: 1px solid #000;
        padding: 5px;
        margin-right: 5px;
        font-weight: bold;
        font-size: 10px;

        &.is-saved-type {
          border-color: $save-color;
        }

        &.is-used-type {
          border-color: $used-color;
        }

        &.is-cancel-type {
          background-color: #000000;
          color: #ffffff;
        }
      }

      .saved-money-item-header-right {
        margin-left: auto;
        display: flex;
        align-items: center;

        .saved-money-item-balance {
          font-weight: 800;
        }
      }
    }

    .saved-money-item-content {
      padding-top: 10px;
      border-top: 1px solid #f3f3f3;

      .saved-money-item-content-text {
        margin-top: 8px;
        margin-bottom: 16px;
        line-height: 1.4;
      }

      .saved-money-item-content-money-wrapper {
        display: flex;
        align-items: center;

        .saved-money-item-small-text {
          width: 35px;
        }

        .saved-money-item-content-money {
          width: 100%;
          margin-left: 20px;
          display: flex;
          align-items: center;

          .saved-money-item-content-money-price {
            width: 50%;
            font-weight: 800;
            font-size: 12px;

            &.saved-money {
              color: $save-color;
            }

            &.used-money {
              color: $used-color;
            }
          }
        }
      }
    }
  }
}
