.provider-crown {
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  position: absolute;
  bottom: 34px;

  .product-detail & {
    bottom: 32px;
  }
}
