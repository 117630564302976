@import 'variables';

.provider-product-item-wrapper {
  display: flex;
  align-items: center;
  padding: 15px;

  .provider-product-item-image {
    width: 55px;
    height: 55px;
    margin-right: 23px;
    position: relative;
    min-width: 55px;

    .provider-product-item-main-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    .provider-product-item-sub-image {
      width: 30px;
      height: 30px;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15px;

      border: 1px solid $provider-color;
      border-radius: 50%;

      &.no-image-border {
        border: 0;
      }
    }
  }

  .provider-product-item-info {
    flex-basis: calc(100% - 55px);

    align-self: flex-end;

    .provider-product-item-info-name {
      font-weight: 800;
      font-size: 12px;
      color: #000000;
      margin-bottom: 5px;
      line-height: 1.3;
    }

    .provider-product-item-info-date {
      span {
        font-size: 10px;
        color: #808081;

        &.provider-product-item-info-d-date {
          margin-left: 8px;
          color: $provider-color;
        }
      }
    }
  }

  .provider-product-link-icon {
    margin-left: auto;
  }
}
