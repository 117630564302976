@import 'variables';

.fortune-modal {
  .headerImage {
    width: 100%;
  }

  .fortune-content-wrapper {
    margin: 50px auto 30px auto;
    width: 75%;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.5;
    text-align: center;
    border-color: $black-color;
    color: $black-color;

    span {
      font-size: 15px;
      font-weight: bold;
      color: grey;
    }
  }

  .share-button-title {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
  }

  .share-button-wrapper {
    margin: 16px auto 28px auto;
    display: flex;
    justify-content: center;
    // flex-direction: column;
    font-weight: bold;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 12px;
      position: relative;

      .share-button-img {
        width: 38px;
        height: 38px;
        margin: 5px;
        background-position: center;
        background-size: cover;
        position: relative;

        .success-copy-url-image {
          width: 52px;
          height: 52px;
          top: -7px;
          left: -7px;
          position: relative;
          // opacity: 70%;
          // animation-name: copy-url-moving;
          // animation-duration: 1.5s;
          // animation-play-state: running;
          // animation-fill-mode: forwards;
        }

        // @keyframes copy-url-moving {
        //   0% {
        //     opacity: 0%;
        //   }
        //   100% {
        //     opacity: 80%;
        //   }
        // }
      }
    }
  }
}
