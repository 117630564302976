.from-search-list-background {
  background-color: #fafafa;
  height: calc(100vh - 186px);
  margin-bottom: -43px;
}

.from-search-list {
  width: 100%;
  padding-top: 10px;
  min-height: calc(100vh - 186px);
  margin-bottom: -43px;
  padding-bottom: 10px;
  background-color: #fafafa;
}

.from-search-list-inner {
  background-color: white;
  margin-bottom: 58px;
}

.from-search-item {
  border-bottom: 1px solid #ddd;
  height: 65px;
}

.from-search-item-link {
  color: #000;

  &:last-of-type {
    .from-search-item {
      border-bottom: 0;
    }
  }
}

.from-search-list + .company-info-form,
.from-search-list-background + .company-info-form,
.search-loader + .company-info-form,
.search-bar + .company-info-form,
.from-search-tag-bar + .company-info-form {
  display: none;
}

.from-search-circle-profile {
  width: 50px;
  height: 50px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  float: left;
}

.from-search-square-profile {
  width: 50px;
  height: 50px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
}
.from-search-name-box {
  width: calc(100% - 70px);

  .from-search-name {
    font-size: 15px;
    color: #000000;
  }
}
