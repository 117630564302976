@import 'variables';

.onestore-count-info {
  display: flex;
  max-width: 420px;
  margin: auto;
  padding: 22px 14px 0 14px;

  .onestore-count-2 {
    display: flex;
    /* width: 25%; */ /* 4개였을때 */
    width: 33.3%; /* 3개 */
    height: 38px;
    padding: 10px 0;
    border: 1px solid $button-border-color;
    border-bottom: 0;
    justify-content: center;
    align-items: center;
    margin-left: 1px;

    font-size: 13px;
    font-weight: bold;
    color: #808080;

    &:first-of-type {
      margin-left: 0;
      align-items: flex-end;

      img {
        width: 28px;
        margin-right: 5px;
      }
    }
  }
}
