@import 'variables';

.follow-button {
  background-color: $from-color;
  color: #ffffff;
  font-weight: bold;
  padding: 3px 13px;
  font-size: 12px;
  border-radius: 2px;

  &.active {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid $button-border-color;
  }

  &.follow-provider-color {
    background-color: $provider-color;
    color: #ffffff;

    &.active {
      background-color: #ffffff;
      color: #000000;
      border: 1px solid $button-border-color;
    }
  }
}

.follow-button-wrapper {
  // .provider-info & {
  //   position: absolute;
  //   top: 16px;
  //   right: 16px;
  //   text-align: center;
  // }
}
