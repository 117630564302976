@import 'variables';

.footer-menu-item {
  position: relative;
  width: 20%;
  height: 100%;
  text-align: center;

  button {
    width: 100%;
  }
}

.footer-menu-item-icon {
  display: block;
  margin: 3px auto 5px auto;
  width: 34px;
  height: 34px;

  &.footer-menu-profile {
    margin: 5px auto 7px auto;
    width: 31px;
    height: 31px;
  }
}

.footer-menu-item-name {
  width: 100%;
  bottom: 5px;
  color: black;
  font-size: 10px;
  font-weight: bold;

  .active & {
    color: $from-color;

    .provider & {
      color: $provider-color;
    }
  }

  .active.provider & {
    color: $provider-color;
  }
}
