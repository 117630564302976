@import 'variables';

.onestore-list-template-item {
  .onestore-list-template-item-label {
    margin-left: auto;
    padding: 3px 13px;
    border-radius: 3px;

    font-weight: bold;
    font-size: 12px;

    &.discover-label-0,
    &.discover-label-1 {
      background-color: #f7e01a;
      color: black;
    }

    &.discover-label-2 {
      background-color: $from-color;
      color: white;
    }

    &.discover-label-3 {
      background-color: $danger-color;
      color: white;
    }
  }

  .onestore-list-template-item-image {
    width: 55px;
    height: 55px;
    margin-right: 23px;
    position: relative;

    .onestore-list-template-item-main-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    .onestore-list-template-item-sub-image {
      width: 30px;
      height: 30px;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15px;

      border: 1px solid $from-color;
      border-radius: 50%;

      &.no-image-border {
        border: 0;
      }
    }
  }

  .onestore-list-template-item-info {
    align-self: flex-end;
    /* 좌측이미지와 우측 라벨을 width를 뺀 값 */
    flex-basis: calc(100% - 150px);

    .onestore-list-template-item-name {
      margin-bottom: 5px;
    }

    .onestore-list-template-item-date {
      span {
        font-size: 10px;
        color: #808081;
      }
    }
  }
}
