.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  // background: rgb(255, 255, 255);
  // opacity: 0.5;
  z-index: 1;
}

.loader-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.loader-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}
