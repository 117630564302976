@import 'variables';
@import 'breakpoint';

.provider-products {
  position: relative;
}

.provider-network {
  border-top: 1px solid $border-color;
  padding: 20px 0;
  text-align: center;

  .store-list {
    margin-top: 20px;
  }
}

.product-sub-title {
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  text-overflow: ellipsis;
}

.product-count {
  color: #2d2a2a;
}
