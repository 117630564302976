@import 'breakpoint';

.from-pick-banner-wrapper {
  position: relative;
  padding-top: 10px;

  .banner-slide {
    overflow: hidden;
    margin: 0 auto;

    .slick-dots {
      bottom: 26px;
    }
  }

  .banner-slide-item {
    position: relative;
    width: 100%;
    height: 0;
    /*
    min-height: 240px;
    padding-top: calc(197 / 375 * 100%); */
    padding-top: 100%;
    background-position: center center;
    background-size: cover;
    margin: 0 auto;
  }
}
