@import 'variables';
@import 'form';

.signin-help {
  max-width: $max-container-width;
  width: 100%;
  margin: 16px auto;
  padding: 16px;
}

.signin-help-buttons {
  .button {
    width: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 3px;
  }
}
