@import 'variables';

.provider-signin {
  display: block;
  text-align: center;

  .form-control {
    border-color: $provider-color;
  }

  .signin-form-buttons {
    a {
      color: $provider-color;
    }
  }
}

.provider-signin-text {
  width: 100%;
  margin: 16px 0 38px;
  font-weight: 800;
  font-size: $font-15;
  text-align: center;
}

.provider-reset-password {
  display: block;
  max-width: $max-container-width;
  width: 100%;
  margin: 30px auto 16px;
  padding: 0 24px;
  text-align: center;
}
