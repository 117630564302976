@import 'variables';

.modal {
  -webkit-overflow-scrolling: touch;
}

.os-modal {
  position: relative;

  &.modal-sm {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-content {
    border: none;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
  }

  .modal-center-top-icon {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);

    width: 64px;
  }

  .modal-header-image {
    margin-top: 8px;
    display: flex;
    align-items: center;

    .decorate-img-wrapper {
      width: 75px;
      margin: 0 auto;

      .decorate-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 0;
        padding-top: 100%;
        width: 100%;
      }
    }

    .center-circle-image {
      width: 95px;
      height: 95px;
      border-radius: 50%;
      background-position: center;
      margin: 0 auto;

      &.center-circle-image-border-fortune {
        width: 120px;
        height: 120px;
        border: 3px solid $yellow-support-color;
        border-radius: 2px;
        background-size: none;
        background-position: center;
      }
      &.center-circle-image-border-from {
        background-size: cover;

        border: 3px solid $from-color;
      }
      &.center-circle-image-border-yellow {
        background-size: cover;

        border: 3px solid $yellow-support-color;
      }
      &.center-circle-image-border-provider {
        background-size: cover;

        border: 3px solid $provider-color;
      }
    }
  }

  .modal-header-text {
    font-size: 18.7px;
    font-weight: 800;
    margin-top: 30px;
  }

  .modal-body-text {
    line-height: 19px;
    margin-top: 30px;
    margin-bottom: 40px;

    span {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 17px;
    height: 19px;
    background-image: url('/images/modal-close-btn.svg');
    text-indent: -1000rem;
    z-index: 1;
    visibility: hidden;
  }
}
