@import 'variables';

.success-modal-link {
  text-decoration: underline;
  color: $from-color;
}

.success-modal-body-text {
  line-height: 19px;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;

  span {
    font-size: 14px;
    font-weight: bold;
  }
}
