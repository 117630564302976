@import 'variables';

.summary-text {
  width: 100%;
}

.summary-button {
  font-size: $font-12;
  color: $from-color;
  bottom: 2px;
  right: 7px;
  height: 16px;
  line-height: 16px;
}
