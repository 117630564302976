@charset "utf-8";

@import url(https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css);

@font-face {
  font-family: 'Nanum Sqaure';
  font-style: normal;
  font-weight: 300;
  src: url('/font/NanumSquareL.woff') format('woff'),
    url('/font/NanumSquareOTFLight.svg') format('svg'),
    url('/font/NanumSquareL.ttf') format('ttf');
}
@font-face {
  font-family: 'Nanum Sqaure';
  font-style: normal;
  font-weight: 400;
  src: url('/font/NanumSquareR.woff') format('woff'),
    url('/font/NanumSquareOTFR.svg') format('svg'),
    url('/font/NanumSquareR.ttf') format('ttf');
}
@font-face {
  font-family: 'Nanum Sqaure';
  font-style: normal;
  font-weight: 700;
  src: url('/font/NanumSquareB.woff') format('woff'),
    url('/font/NanumSquareOTFB.svg') format('svg'),
    url('/font/NanumSquareB.ttf') format('ttf');
}
@font-face {
  font-family: 'Nanum Sqaure';
  font-style: normal;
  font-weight: 800;
  src: url('/font/NanumSquareEB.woff') format('woff'),
    url('/font/NanumSquareOTFExtraBold.svg') format('svg'),
    url('/font/NanumSquareEB.ttf') format('ttf');
}
